import React, { useState, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import SEO from "./Seo";
import ContactIng from '../assets/in_agriculture.jpg';
import EnSeries from "../assets/tractor/3028EN.webp";
import Eseries from "../assets/tractor/model-5075.png";
import Dseries from "../assets/tractor/model-5405.png";

const sizes = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
};

export const devices = {
    mobileS: `(min-width: ${sizes.mobileS})`,
    mobileM: `(min-width: ${sizes.mobileM})`,
    mobileL: `(min-width: ${sizes.mobileL})`,
    tablet: `(min-width: ${sizes.tablet})`,
    laptop: `(min-width: ${sizes.laptop})`,
    laptopL: `(min-width: ${sizes.laptopL})`,
    desktop: `(min-width: ${sizes.desktop})`,
};

export const CardImageContainer = tw.img`text-base`;

export const ImageContainer = styled.img`
filter: brightness(50%);
width: 100%;
@media(max-width:768px){
    height: 302px !important;
    width: 100% !important;
    object-fit: cover !important;
}
`
export const ImageLayer = styled.div`
position: absolute;
bottom: 70px;
left: 115px;
text-color: white;
text-align: left;
@media${devices.mobileS} {
    max-width: 768px; 
    bottom: 30px;
    left: 30px;
}
@media ${devices.tablet}{
    max-width: 1024px;
    bottom: 35px;
    left: 90px;
}
`
const HeadText = styled.span`
font-size: 4rem;
font-weight: bold;
color: white;
`
const Description = styled.p`
overflow: hidden;
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 5; 
white-space: pre-wrap;
`
const ReadMore = styled.p`
color: #c7081b !important;
float: bottom;
text-decoration: none;
cursor: pointer;
&:hover{
text-decoration: underline;
}
`
const CardContiner = styled.div`
margin: 20px;
padding: 0px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 15px;
`
const DescDiv = styled.div`
padding: 10px;
`
const Image = styled.img`
width:100%;
height:200px;
`
const Title = styled.h5`
font-weight: 700;
`

const Card = styled.div`
@media(min-width:768px){
    flex-direction: row !important;
}
display: flex;
flex-direction: column;
`
export default function Tractors() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const onPageLoad = () => {
            console.log('page loaded');
            setLoading(false);
        };
        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad, false);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    return (
        <>
            <SEO
                title='Tractors - Races'
                description='John Deere Tractors'
                name='Races'
                type='article' />
            <div className="containerClass">
                <ImageContainer src={ContactIng} alt="about-us" />
                <ImageLayer>
                    <HeadText>Tractor</HeadText>
                </ImageLayer>
            </div>
            <div className="container" tw="lg:py-16 py-10">
                <Card>
                    <CardContiner key="1" className="col">
                        <Link to="/en-series" style={{ textDecoration: "none" }}>
                            {loading ? (
                                <Skeleton
                                    height="200px"
                                    containerClassName="productContainer"
                                />
                            ) : (
                                <Image src={EnSeries} />
                            )}
                            <DescDiv >
                                {loading ? <Skeleton width={200} height={20} /> : (
                                    <Title className="color py-2">EN Series Tractors</Title>
                                )}
                                {loading ? <Skeleton count={3.2} height={20} /> :
                                    <Description>5C series Tractors are available in 35HP to 42 HP ratings and catering to the farmers having low to medium land holding patterns.</Description>
                                }
                                {loading ? <Skeleton width={150} height={30} /> : (
                                    <ReadMore>View More &gt;</ReadMore>
                                )}
                            </DescDiv>
                        </Link>
                    </CardContiner>
                    <CardContiner key="2" className="col">
                        <Link to="/d-series" style={{ textDecoration: "none" }}>
                            {loading ? (
                                <Skeleton
                                    height="200px"
                                    containerClassName="productContainer"
                                />
                            ) : (
                                <Image src={Dseries} alt="d-series" />
                            )}
                            <DescDiv >
                                {loading ? <Skeleton width={200} height={20} /> : (
                                    <Title className="color py-2">D Series Tractors</Title>
                                )}
                                {loading ? <Skeleton count={5} height={20} /> :
                                    <Description>5D series tractors are available in 36 to 50 HP ratings and are suitable for multi utility applications, optimized performance for Agricultural application and heavy duty haulage. These tractors brings power of value +++ , that means More Productive , More Torque and better fuel efficiency for all kind of agricultural work. With an engine horsepower range from 36HP to 50 HP, these tractors are built to exceed your toughest expectations.</Description>
                                }
                                {loading ? <Skeleton width={150} height={30} /> : (
                                    <ReadMore>View More &gt;</ReadMore>
                                )}
                            </DescDiv>
                        </Link>
                    </CardContiner>
                    <CardContiner className="col" key="3">
                        <Link to="/e-series" style={{ textDecoration: "none" }}>
                            {loading ? (
                                <Skeleton
                                    height="200px"
                                    containerClassName="productContainer"
                                />
                            ) : (
                                <Image src={Eseries} alt="e-series" />
                            )}
                            <DescDiv >
                                {loading ? <Skeleton width={200} height={20} /> : (
                                    <Title className="color py-2">E Series Tractors</Title>
                                )}
                                {loading ? <Skeleton count={5} height={20} /> :
                                    <Description>5E series Tractors are available in 50HP to 75 HP ratings and cater to the farmers having large and very large land holding patterns .These tractors are specially designed for heavy duty applications and handle large size implements and heavy loads.</Description>
                                }
                                {loading ? <Skeleton width={150} height={30} /> : (
                                    <ReadMore>View More &gt;</ReadMore>
                                )}
                            </DescDiv>
                        </Link>
                    </CardContiner>
                </Card>
            </div>
        </>
    );
};