import React from "react";
import "twin.macro";
import styled from "styled-components";
import {
    Title,
    Container,
    CardContainer,
    ImageContainer,
    ImageOverlayLayer,
    ImageOverlayText
} from './CommonComponent.js';
import SEO from "./Seo.js";
import Harvestor1 from '../assets/slider02.jpeg';
import Harvestor2 from '../assets/slider01.jpeg';
import ContactIng from '../assets/in_agriculture.jpg';
// import Cseries from "../assets/c_series_main.jpg";

const Image = styled.img`
width:100%;
`
export default function Harvestors() {

    return (
        <>
            <SEO
                title='Harvestor - Races'
                description='John Deere and Standart Harvestors'
                name='Races'
                type='article' />
            <div className="containerClass">
                <ImageContainer src={ContactIng} alt="about-us" />
                <ImageOverlayLayer>
                    <ImageOverlayText>Harvestor</ImageOverlayText>
                </ImageOverlayLayer>
            </div>
            <div className="container" tw="lg:py-16 py-10">
                <div className="row">
                    <Container className="col-md-6">
                        <CardContainer>
                            <Image src={Harvestor1} alt="harvestor" />
                            <Title className="py-3 px-2">45" Standard Combine Harvesters -Two Wheel Drive</Title>
                        </CardContainer>
                    </Container>
                    <Container className="col-md-6">
                        <CardContainer>
                            <Image src={Harvestor1} />
                            <Title className="py-3 px-2">51" Standard Combine Harvesters -Two Wheel Drive</Title>
                        </CardContainer>
                    </Container>
                    <Container className="col-md-6">
                        <CardContainer>
                            <Image src={Harvestor2} />
                            <Title className="py-3 px-2">45" Standard Combine Harvesters-Four Wheel Drive</Title>
                        </CardContainer>
                    </Container>
                    <Container className="col-md-6">
                        <CardContainer>
                            <Image src={Harvestor2} />
                            <Title className="py-3 px-2">51" Standard Combine Harvesters-Four Wheel Drive</Title>
                        </CardContainer>
                    </Container>
                </div>
            </div>
        </>
    );
};