import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
}

export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktop})`
};

const StickyElement = styled(Link)`
background-color: #3a7f44;
color: white !important;
text-align: center;
font-weight: bold;
text-decoration: none !important;
width: 150px;  
top: 60%;

z-index: 9;
-webkit-transform: rotate(-90deg); 
-moz-transform: rotate(-90deg);    
transform:rotate(-90deg);
position: fixed !important; 
position: -webkit-sticky;
@media ${device.mobileS} { 
    padding-top: 5px;
    padding-bottom: 5px;
    height: 45px;
    right: -65px;
  }
@media ${device.laptop} { 
    padding-top: 15px;
    padding-bottom: 15px;
    height: 50px;
    right: -50px;
  }
&:hover{
    color: #fce94f !important;
}
`
export default function FindUs() {
    return (
        <StickyElement to='/map'>
            <i className="fa fa-map-marker" aria-hidden="true" tw="text-xl" />{' '}{' '}  Find Us
        </StickyElement>
    );
};