import React from "react";
import { Container, ContainerFluid } from "../components/Wrapper.js";
import Header from "../components/headers/Header.js";
import Footer from "../components/footers/Footer.js";
import Dseries from "../components/Dseries.js";

export default function CseriesPage(){
  return (
    <Container>
      <Header isActiveTab="3" />
      <ContainerFluid>
        <Dseries />
      </ContainerFluid>
      <ContainerFluid>
        <Footer />
      </ContainerFluid>
    </Container>
  );
};