import React from "react";
import { Container, ContainerFluid } from "../components/Wrapper.js";
import Header from "../components/headers/Header.js";
import Footer from "../components/footers/Footer.js";
import ContactUs from "../components/ContactUs.js";

export default function Contact() {
  return (
    <React.Fragment>
      <Container>
        <Header isActiveTab="5" />
        <ContainerFluid>
          <ContactUs />
        </ContainerFluid>
        <ContainerFluid>
          <Footer />
        </ContainerFluid>
      </Container>
    </React.Fragment>
  );
};