import React from "react";
import { Container, ContainerFluid } from "../components/Wrapper.js";
import Header from "../components/headers/Header.js";
import Footer from "../components/footers/Footer.js";
import Gallery from "../components/Gallery.js";

export default function Harvestor(){
  return (
    <Container>
      <Header isActiveTab="1"/>
      <ContainerFluid>
        <Gallery />
      </ContainerFluid>
      <ContainerFluid>
        <Footer />
      </ContainerFluid>
    </Container>
  );
};