import React from "react";
import styled from "styled-components";

const Div = styled.div`
position: relative`;

const SubDiv = styled.div`
overflow: hidden;
`
function Container(props){
    return <Div>{props.children}</Div>
};

function ContainerFluid(props){
    return <SubDiv>{props.children}</SubDiv>
};

export {
    Container,
    ContainerFluid
};