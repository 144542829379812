import styled from "styled-components";
import { Link } from "react-router-dom";

const sizes = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
};

const devices = {
    mobileS: `(min-width: ${sizes.mobileS})`,
    mobileM: `(min-width: ${sizes.mobileM})`,
    mobileL: `(min-width: ${sizes.mobileL})`,
    tablet: `(min-width: ${sizes.tablet})`,
    laptop: `(min-width: ${sizes.laptop})`,
    laptopL: `(min-width: ${sizes.laptopL})`,
    desktop: `(min-width: ${sizes.desktop})`,
};

const Card = styled.div`
@media(min-width:768px){
    flex-direction: row !important;
}
display: flex;
flex-direction: column;
`

const CardContainer = styled.div`
margin: 5px;
padding: 1px !important;
border: 0.1px solid #c2c2c291;
height: 100%;
&:hover{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
`
const Container = styled.div`
padding-top: 0.5rem; 
padding-bottom: 0.5rem;
&:hover{
    transform: scale(1.04) !important;
    transition: transform 0.5s;
}
`
const Image = styled.img`
width:100%;
height: 250px;
`
const Title = styled.h5`
font-weight: 700;
color: #c7081b !important;
`

const ImageOverlayLayer = styled.div`
position: absolute;
bottom: 30px;
left: 30px;
text-color: white;
text-align: left;
@media ${devices.tablet} {
    max-width: 768px; 
    bottom: 70px;
    left: 115px;
}
`
const ImageOverlayText = styled.span`
font-size: 3rem;
font-weight: bold;
color: white;
@media ${devices.tablet} {
    font-size: 4rem;
}
`
const Description = styled.p`
overflow: hidden;
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 3; 
white-space: pre-wrap;
`

const ImageContainer = styled.img`
filter: brightness(50%);
width: ${props => props.width ? props.width : "100%"};
height: ${props => props.height ? props.height : ''};
@media(max-width:768px){
    height: 250px !important;
    width: 100% !important;
    filter: brightness(15%);
    object-fit: fill !important;
}
`
const CategoryLink = styled(Link)`
text-decoration: none !important;
color: #ffffff !important;
&:hover{
    color: #ffffff !important;
}
`
const EventContainer = styled.div`
display: flex;
flex-direction: column;
flex-wrap: inherit;
padding-left: 10px;
padding-right: 10px;
@media ${devices.laptop}{
    flex-direction: row;
    padding-left: 4rem;
    padding-right: 4rem;
}
`

const EventSubContainer = styled.div`
flex-direction: column;
text-align: center;
color: white;
`
const EventOverlay = styled.div`
background: #c7081bdb;
position: absolute;
height: 100% !important;
width: 100%;
left: 0;
top: 0;
bottom: 0;
right: 0;
opacity: 0;
-webkit-transition: all 0.4s ease-in-out 0s;
-moz-transition: all 0.4s ease-in-out 0s;
transition: all 0.4s ease-in-out 0s;
`
const EventDetails = styled.div`
position: absolute;
text-align: center;
padding-left: 1em;
padding-right: 1em;
width: 100%;
top: 80%;
left: 50%;
opacity: 0;
-webkit-transform: translate(-50%, -50%);
-moz-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
-webkit-transition: all 0.3s ease-in-out 0s;
-moz-transition: all 0.3s ease-in-out 0s;
transition: all 0.3s ease-in-out 0s;

 h3{
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.15em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
 }
 p{
    color: #fff;
    font-size: 0.8em;
 }
`
const Events = styled.div`
position: relative;
width: 90%;
max-width: 400px;
margin: auto;
overflow: hidden;
margin-top: 10px;
margin-bottom: 10px;
 ${EventOverlay}{
    background: #c7081bdb;
    position: absolute;
    height: 99%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
 }
    &:hover{
        ${EventOverlay}{
        opacity: 1;
        }
        ${EventDetails}{
            top: 50%;
            left: 50%;
            opacity: 1;
        }
    }
`

const EventImage = styled.img`
width: 100%;
height: 200px;
`

const RatingContainer = styled.div`
margin-bottom: 10px;
margin-top: 10px;
display: flex;
justify-content: center;
`
const ReviewContainer = styled.div`
background-color: #fafafa;
width: 90%;
margin-left: 5%;
margin-right: 5%;
margin-top: 2%;
margin-bottom: 8%;
padding-top: 6%;
padding-bottom: 8%;
padding-left: 5%;
padding-right: 5%;
border: 1px solid #ddd;
border-radius: 45px;
box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
height: 350px;
@media ${devices.tablet}{
    width: 320px;
    margin-bottom: 8%;
    margin-top: 3%
}
h5{
    color: #222;
    font-weight: 100;
    letter-spacing: 0.2px;
    padding-top: ${props => props.padding ? '30px' : '20px'};
    margin-bottom: 4px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 17px;
}
h4{
    text-transform: uppercase;
    margin-top: 0;
    padding-top: 0;
    font-weight: 500;
    color: #787878;
    font-size: 14px;
}
p{
    font-weight: 100 !important;
    line-height: 29px !important;
    color: #222;
    font-size: 15px;
    font-family: sans-serif;
    max-height: 67px;
}
p:before{
    content: "“";
    color: #aaa;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100;
}
p:after{
    content: "”";
    color: #aaa;
    font-size: 26px;
    font-family: monospace;
    font-weight: 100;
    line-height: 0;
}
`
const ReviewImage = styled.img`
width: ${props => props.width ? props.width : "18%"};
height: auto;
margin: auto;
`

const EnquiryButton = styled.div`
width: 100%;
padding-top: 10px;
padding-bottom: 10px;
button{
    display: block;
    background: white;
    height: 55px;
    width: 100%;
    font-size: 18px;
    font-family: sans-serif;
    text-decoration: none;
    color: #333;
    border: 0.5px solid #333;
    letter-spacing: 2px;
    text-align: center;
    position: relative;
    transition: all .35s;
  }
  
  button span{
    position: relative;
  }
  
  button:after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: #c7081b;
    transition: all .35s;
  }
  
  button:hover{
    color: #fff !important;
    span{
        z-index: 2;
    }
  }
  
  button:hover:after{
    width: 100%;
  }
`
export {
    Card,
    Events,
    EventImage,
    EventDetails,
    EventOverlay,
    EnquiryButton,
    EventContainer,
    EventSubContainer,
    CardContainer,
    CategoryLink,
    Image,
    ImageOverlayLayer,
    Title,
    ImageOverlayText,
    ImageContainer,
    RatingContainer,
    ReviewContainer,
    ReviewImage,
    Description,
    Container,
    devices
}