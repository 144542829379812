import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import "twin.macro";
import {
    ImageContainer,
    ImageOverlayLayer,
    ImageOverlayText,
    devices
} from './CommonComponent.js';
import SEO from "./Seo.js";
import AboutImg from '../assets/Slider-Compacts-v1-2.jpg';
import GooglePlay from "../assets/googleplay.png";
import ServiceImg from "../assets/service.jpg";
import Anubhuti from "../assets/anubhuthi.webp";
import AnubhutiSm from "../assets/anubhuthism.webp";


const Button = styled.button`
  align-items: center;
  background-color: #fff;
  border: 2px solid #000;
  box-sizing: border-box;
  color: #c7081b;
  cursor: pointer;
  display: inline-flex;
  fill: #c7081b;
  font-family: Inter,sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  justify-content: center;
  letter-spacing: -.8px;
  line-height: 24px;
  min-width: 140px;
  outline: 0;
  padding: 0 17px;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

&:focus {
  color: #171e29;
}

&:hover {
  border-color: #c7081b;
  color: #c7081b;
  fill:#c7081b;
}

&:active {
  border-color: #c7081b;
  color: #c7081b;
  fill: #c7081b;
}

@media ${devices.tablet} {
    min-width: 170px;
}
`

const ButtonLink = styled(Link)`
text-decoration: none !important;
`
const IconContainer = styled.div`
position: relative;
`
const IconImage = styled.img`
border-radius: 25%;
`
const SmallImg = styled.img`
bottom: -10px;
filter: blur(10px);
left: 20px;
opacity: 50%;
position: absolute;
z-index: -1;
border-radius: 25%;
`
export default function Service() {
    return (
        <>
            <SEO
                title='Service - Races'
                description='John Deere Service'
                name='Races'
                type='article' />
            <div className="containerClass">
                <ImageContainer src={AboutImg} alt="about-us" />
                <ImageOverlayLayer data-aos={"fade-down"} data-aos-duration={"1000"}>
                    <ImageOverlayText>Service</ImageOverlayText>
                </ImageOverlayLayer>
            </div>
            <div className="container" tw="lg:py-16 py-10">
                <div className="row flex-column flex-lg-row">
                    <div className="col-md-12 col-lg-6 px-4">
                        <div className="py-4 px-2">
                            <h4 className="color" tw="font-bold">SERVICE CENTERS</h4>
                            <img src={ServiceImg} width="100%" height="300px" alt="service" />
                            <p className="py-2"><span className="firstcharacter">S</span>In efforts to make Service is Key to our success, We make service availability easy for every customer. And they can easily access our branch locations without a thought.</p>
                            <ButtonLink to="/contact">
                                <Button>
                                    View All Locations
                                </Button>
                            </ButtonLink>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6 px-4">
                        <div className="py-4">
                            <h4 className="color" tw="font-bold">ORDER ONLINE</h4>
                            <div className="py-2">
                                <p>Anubhuti App provides access to John Deere’s dealerships and tractor local technicians on finger tips. Browse through the products your nearest dealerships are offering for sale and contact the them for knowing more about prices and offers. Register your tractor or harvester in the app, and book service appointments.</p>
                                <IconContainer>
                                    <IconImage src={Anubhuti} width="240px" height="240px" alt="icon-img" />
                                    <SmallImg src={AnubhutiSm} width="200px" height="200px" alt="icon-img" />
                                </IconContainer>
                                <div className="py-2">
                                    <a href="https://play.google.com/store/apps/details?id=com.deere.anubhuti.main&hl=en_IN&gl=US" target="_blank" rel="noreferrer" >
                                        <img src={GooglePlay} width="300px" height="100%" alt="google-play" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};