import React, { useEffect, useState } from "react";
import "twin.macro";
import {
    ImageContainer,
    ImageOverlayLayer,
    ImageOverlayText
} from './CommonComponent.js';
import SEO from "./Seo.js";
import styled from "styled-components";
import ContactIng from '../assets/in_agriculture.jpg';
import branchesData from "../data/BranchData.js";
import Flipper from "./Flipper.js";

const Address = styled.p`
white-space: pre-line !important;
`
export default function ContactUs() {
    const [data, setData] = useState([]);

    useEffect(() => {
        const brachInfo = branchesData;
        return (
            setData(brachInfo)
        );
    }, []);

    return (
        <>
            <SEO
                title='Contact Us - Races'
                description='Beginner friendly page for learning React Helmet.'
                name='Company name.'
                type='article' />
            <div className="containerClass">
                <ImageContainer src={ContactIng} alt="about-us" />
                <ImageOverlayLayer data-aos={"fade-down"} data-aos-duration={"1000"}>
                    <ImageOverlayText>Contact Us</ImageOverlayText>
                </ImageOverlayLayer>
            </div>
            <div className="container" tw="lg:py-16 py-10">
                <div className="row align-items-center flex-column flex-lg-row">
                    <div className="col-md-12 col-lg-12">
                        <div className="py-4 text-center">
                            <p>Placing customer satisfaction first, Integrating sales with service and serviceparts in a single convenient location, we contribute to speedy and efficient service, allowing customers to experience the convience.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-lg-12 px-2">
                    <div className="py-4">
                        <h4 className="color" tw="font-bold my-5">OUR BRACHES INFORMATION</h4>
                    </div>
                    <div className="row">
                        {data.map(item => {
                            return (
                                <>
                                    {
                                        (() => {
                                            if (item.isHeadOffice)
                                                return (
                                                    <div style={{ fontSize: "15px" }} className="col-md-4 p-3" key={item.id}>
                                                        <Flipper
                                                            title={item.branch}
                                                            image={item.image}
                                                            iFrameMap={item.iFrameMap}
                                                        />
                                                        <h5 className="color" tw="font-bold my-5 uppercase">{item.branch}</h5>
                                                        <table>
                                                            <tr className="py-1">
                                                                <td style={{width:'40%'}} tw="font-bold"><i className="fa fa-map-marker px-2 py-1" />Address :</td>
                                                                <td><Address>{item.address}</Address></td>
                                                            </tr>
                                                            <tr className="py-1">
                                                                <td tw="font-bold"><i className="fa fa-phone px-2 py-1" />Phone :</td>
                                                                <td><p>{item.phone}</p></td>
                                                            </tr>
                                                            <tr className="py-1">
                                                                <td tw="font-bold"> <i className="fa fa-envelope px-2 py-1" />Mail :</td>
                                                                <td><p>{item.mail}</p></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                )
                                        })()
                                    }
                                </>
                            )
                        })}
                    </div>
                </div>
                <div className="col-md-12 col-lg-12 px-2">
                    <div className="py-4">
                        <h4 className="color" tw="font-bold my-5">BRACHES WITH 3S FACILITY</h4>
                    </div>
                    <div className="row">
                        {data.map(item => {
                            return (
                                <>
                                    {
                                        (() => {
                                            if (!item.isHeadOffice)
                                                return (
                                                    <div style={{ fontSize: "15px" }} className="col-md-4 p-3" key={item.id}>
                                                        <Flipper
                                                            title={item.branch}
                                                            image={item.image}
                                                            iFrameMap={item.iFrameMap}
                                                        />
                                                        <h5 className="color" tw="font-bold my-5 uppercase">{item.branch}</h5>
                                                        <table>
                                                            <tr className="py-1">
                                                                <td style={{width:'40%'}} tw="font-bold"><i className="fa fa-map-marker px-2 py-1" />Address :</td>
                                                                <td><Address>{item.address}</Address></td>
                                                            </tr>
                                                            <tr className="py-1">
                                                                <td tw="font-bold"><i className="fa fa-phone px-2 py-1" />Phone :</td>
                                                                <td><p>{item.phone}</p></td>
                                                            </tr>
                                                            <tr className="py-1">
                                                                <td tw="font-bold"> <i className="fa fa-envelope px-2 py-1" />Mail :</td>
                                                                <td><p>{item.mail}</p></td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                );
                                        })()
                                    }
                                </>
                            )
                        })}
                    </div>
                </div>
                <div className="col-md-12 col-lg-12 px-2">
                    <div className="py-4">
                        <h4 className="color" tw="font-bold my-5">Business Hour</h4>
                    </div>
                    <div className="row" tw="justify-center px-20">
                        <div className="col" tw="px-3">
                            <h5 className="color" tw="font-medium">Day of the Week</h5>
                            <p>Monday</p>
                            <p>Tuesday</p>
                            <p>Wednesday</p>
                            <p>Thursday</p>
                            <p>Friday</p>
                            <p>Saturday</p>
                        </div>
                        <div className="col" tw="text-center px-3">
                            <h5 className="color" tw="font-medium">Hours of Operation</h5>
                            <p>9:00am - 6:00pm</p>
                            <p>9:00am - 6:00pm</p>
                            <p>9:00am - 6:00pm</p>
                            <p>9:00am - 6:00pm</p>
                            <p>9:00am - 6:00pm</p>
                            <p>9:00am - 6:00pm</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};