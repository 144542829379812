import Model3028 from "../assets/tractor/model-3028.png";
import Model3036 from "../assets/tractor/model-3036.png";
import Model5105 from "../assets/tractor/model-5105.png";
import Model51051 from "../assets/tractor/model-51051.jpeg";
import Model5045 from "../assets/tractor/model-5045.webp";
import Model50451 from "../assets/tractor/model-50451.png";
import Model5310 from "../assets/tractor/model-5310.png";
import Model53101 from "../assets/tractor/model-53101.png";
import Model5405 from "../assets/tractor/model-5405.png";
import Model54051 from "../assets/tractor/model-54051.png";
import Model5050 from "../assets/tractor/model-5050.png";
import Model5210 from "../assets/tractor/model-5210.png";
import Model52101 from "../assets/tractor/model-52101.png";
import Model5075 from "../assets/tractor/model-5075.png";

const Tractor = [
    {
        "id": "jd3028en",
        "series": "en",
        "model": "JOHN DEERE 3028 EN",
        "driveType": "4WD",
        "engine": {
            "hpCategory": "28 HP",
            "engineRatedRPM": "2800 RPM",
            "noOfCylinder": "3",
            "airFilter": "Dry type, Dual element",
            "coolingSystem": "Coolant cooled with overflow reservoir"
        },
        "transmission": {
            "clutchType": "Single Clutch",
            "transmissionType": "Sync Reverser / Collarshift",
            "gearLevelPosition": "Side Shift",
            "speedMinMax": "1.6 – 20.7 kmph",
            "reverseSpeedMinMax": "1.5 - 19.8 kmph",
            "forwardGears": 8,
            "reverseGears": 8
        },
        "brakes": {
            "brakeType": "Oil Immersed Disc Brakes",
            "turningRadiusWithBrake": "2300 mm"
        },
        "steering": {
            "steeringType": "Power Steering",
            "steeringAdjustment": "NO"
        },
        "pto": {
            "ptoType": "Independent 6 Splines",
            "ptoRPM": "540 @ 2490,1925 ERPM",
            "ptoPower": "22.5 HP"
        },
        "fuelCapacity": {
            "fuelTankCapacity": "32 litres"
        },
        "dimensionAndWeight": {
            "Weight": "1070 KG",
            "Wheelbase": "1574 MM",
            "Overall Length": "2520 MM",
            "Tractor Width": "1060 MM",
            "Ground Clearance": "285 MM",
        },
        "liftingCapacity": {
            "liftCapacityInKg": "910 kg",
            "hydraulicControls": "ADDC"
        },
        "tyreSize": {
            "front": "6 X 14",
            "rear": "8.3 X 24 / 9.5 X 24",
        },
        "additionalFeature": {
            "driveType": "4WD",
            "accessories": "Ballast weights / Trailer Brake Valve"
        },
        "warranty": "5000 hr/5 year",
        "image": Model3028,
        "description": "John Deere 3028EN is a 28 HP multipurpose tractor. It is best suited for vineyards, vegetable crops and inter-culture operations. Its slender design and power packed engine provides excellent fuel efficiency and productivity."
    },
    {
        "id": "jd3036en",
        "series": "en",
        "model": "JOHN DEERE 3036 EN",
        "driveType": '4WD',
        "engine": {
            "hpCategory": "35 HP",
            "engineCapacity": "1500 cc",
            "engineRatedRPM": "2800 RPM",
            "noOfCylinder": "3",
            "airFilter": "Dry type, Dual element",
            "coolingSystem": "Coolant cooled with overflow reservoir, Naturally Aspirated"
        },
        "transmission": {
            "clutchType": "Single Clutch",
            "transmissionType": "Sync Reverser / Collarshift",
            "gearLevelPosition": "Side Shift",
            "speedMinMax": "1.60 - 19.5 kmph",
            "reverseSpeedMinMax": "1.70 - 20.3 kmph",
            "forwardGears": 8,
            "reverseGears": 8
        },
        "brakes": {
            "brakeType": "Oil Immersed Disc Brakes",
            "turningRadiusWithBrake": "2300 mm"
        },
        "steering": {
            "steeringType": "Power Steering",
            "steeringAdjustment": "NO"
        },
        "pto": {
            "ptoType": "6 Splines",
            "ptoRPM": "540 @ 2490,1925 ERPM",
            "ptoPower": "30.6 HP"
        },
        "fuelCapacity": {
            "fuelTankCapacity": "32 litres"
        },
        "dimensionAndWeight": {
            "Weight": "1070 KG",
            "Wheelbase": "1574 MM",
            "overallLength": "2520 MM",
            "tractorWidth": "1040 MM",
            "groundClearance": "285 MM",
        },
        "liftingCapacity": {
            "liftCapacityInKg": "910 kg",
            "hydraulicControls": "ADDC"
        },
        "tyreSize": {
            "front": "6 X 14",
            "rear": "8.3 X 24",
        },
        "additionalFeature": {
            "driveType": "4WD",
            "accessories": "Ballast weights / Trailer Brake Valve"
        },
        "warranty": "5000 hr/5 year",
        "image": Model3036,
        "description": "John Deere 3036EN is a 35 HP multipurpose tractor. It is best suited for vineyards, vegetable crops and inter-culture operations. Its slender design and power packed engine provides excellent fuel efficiency and productivity."
    },
    {
        "id": "jd51052wd",
        "series": "d",
        "model": "JOHN DEERE 5105 2WD",
        "driveType": '2WD',
        "engine": {
            "hpCategory": "40 HP",
            "engineCapacity": "2900 cc",
            "engineRatedRPM": "2100 RPM",
            "noOfCylinder": "3",
            "airFilter": "Dry type, Dual element",
            "coolingSystem": "Coolant Cooled"
        },
        "transmission": {
            "clutchType": "Single / Dual Clutch",
            "transmissionType": "Collarshift",
            "gearLevelPosition": "Side Shift",
            "speedMinMax": "2.84 - 31.70 kmph",
            "reverseSpeedMinMax": "3.74 - 13.52 kmph",
            "forwardGears": 8,
            "reverseGears": 4
        },
        "brakes": {
            "brakeType": "Oil Immersed",
            "turningRadiusWithBrake": "2900 mm"
        },
        "steering": {
            "steeringType": "Power Steering",
            "steeringAdjustment": "NO"
        },
        "pto": {
            "ptoType": "Independent 6 Splines",
            "ptoRPM": "540",
            "ptoPower": "34 HP"
        },
        "fuelCapacity": {
            "fuelTankCapacity": "60 litres"
        },
        "dimensionAndWeight": {
            "Weight": "1810 KG",
            "Wheelbase": "1970 MM",
            "overallLength": "3410 MM",
            "tractorWidth": "",
            "groundClearance": "",
        },
        "liftingCapacity": {
            "liftCapacityInKg": "1600 kg",
            "3PointLinkage": "Category - II",
            "hydraulicControls": "ADDC"
        },
        "tyreSize": {
            "front": "6.0 x 16 / 8 x 18",
            "rear": "13.6 X 28",
        },
        "additionalFeature": {
            "driveType": "2WD / 4WD",
            "accessories": "Ballast weights / Canopy / Canopy holder / Draw bar / Wagon hitch"
        },
        "warranty": "5000 hr/5 year",
        "image": Model5105,
        "description": "John Deere tractor 5105 is offered in both 2 WD and 4 WD, in the 40 HP tractor segment. Packed with power, this heavy-duty agricultural tractor is best suited for both dry and wet land cultivation."
    },
    {
        "id": "jd51054wd",
        "series": "d",
        "model": "JOHN DEERE 5105 4WD",
        "driveType": '4WD',
        "engine": {
            "hpCategory": "40 HP",
            "engineCapacity": "2900 cc",
            "engineRatedRPM": "2100 RPM",
            "noOfCylinder": "3",
            "airFilter": "Dry type, Dual element",
            "coolingSystem": "Coolant Cooled"
        },
        "transmission": {
            "clutchType": "Single / Dual Clutch",
            "transmissionType": "Collarshift",
            "gearLevelPosition": "Side Shift",
            "speedMinMax": "2.84 - 31.70 kmph",
            "reverseSpeedMinMax": "3.74 - 13.52 kmph",
            "forwardGears": 8,
            "reverseGears": 4
        },
        "brakes": {
            "brakeType": "Oil Immersed",
            "turningRadiusWithBrake": "2900 mm"
        },
        "steering": {
            "steeringType": "Power Steering",
            "steeringAdjustment": "NO"
        },
        "pto": {
            "ptoType": "Independent 6 Splines",
            "ptoRPM": "540",
            "ptoPower": "34 HP"
        },
        "fuelCapacity": {
            "fuelTankCapacity": "60 litres"
        },
        "dimensionAndWeight": {
            "weight": "1810 KG",
            "wheelbase": "1970 MM",
            "overallLength": "3410 MM",
            "tractorWidth": "",
            "groundClearance": "",
        },
        "liftingCapacity": {
            "liftCapacityInKg": "1600 kg",
            "3PointLinkage": "Category - II",
            "hydraulicControls": "ADDC"
        },
        "tyreSize": {
            "front": "6.0 x 16 / 8 x 18",
            "rear": "13.6 X 28",
        },
        "additionalFeature": {
            "driveType": "2WD / 4WD",
            "accessories": "Ballast weights / Canopy / Canopy holder / Draw bar / Wagon hitch"
        },
        "warranty": "5000 hr/5 year",
        "image": Model51051,
        "description": "John Deere tractor 5105 is offered in both 2 WD and 4 WD, in the 40 HP tractor segment. Packed with power, this heavy-duty agricultural tractor is best suited for both dry and wet land cultivation."
    },
    {
        "id": "jd5045d2wd",
        "series": "d",
        "model": "JOHN DEERE 5045D 2WD",
        "driveType": '2WD',
        "engine": {
            "hpCategory": "45 HP",
            "engineCapacity": "2900 cc",
            "engineRatedRPM": "2100 RPM",
            "noOfCylinder": "3",
            "airFilter": "Dry type, Dual element",
            "coolingSystem": "Coolant cooled with overflow reservoir"
        },
        "transmission": {
            "clutchType": "Single / Dual Clutch",
            "transmissionType": "Collarshift",
            "gearLevelPosition": null,
            "speedMinMax": "2.83 – 30.92 kmph",
            "reverseSpeedMinMax": "3.71 – 13.43 kmph",
            "forwardGears": 8,
            "reverseGears": 4
        },
        "brakes": {
            "brakeType": "Oil Immersed",
            "turningRadiusWithBrake": "2900 mm"
        },
        "steering": {
            "steeringType": "Power Steering",
            "steeringAdjustment": "NO"
        },
        "pto": {
            "ptoType": "Independent 6 Splines",
            "ptoRPM": "540 @ 2100,1600 ERPM",
            "ptoPower": "38.20 HP"
        },
        "fuelCapacity": {
            "fuelTankCapacity": "60 litres"
        },
        "dimensionAndWeight": {
            "weight": "1810 KG",
            "wheelbase": "1970 MM",
            "overallLength": "3410 MM",
            "tractorWidth": "1810 MM",
            "groundClearance": "415 MM",
        },
        "liftingCapacity": {
            "liftCapacityInKg": "1600 kg",
            "3PointLinkage": "Category - II",
            "hydraulicControls": "ADDC"
        },
        "tyreSize": {
            "front": "6.0 x 16 / 8 x 18",
            "rear": "13.6 X 28",
        },
        "additionalFeature": {
            "driveType": "2WD / 4WD",
            "accessories": "Ballast weights / Canopy / Canopy holder / Draw bar / Wagon hitch"
        },
        "warranty": "5000 hr/5 year",
        "image": Model5045,
        "description": "John Deere 5045 is a power packed agricultural tractor with high back up torque and accelerated productivity towards all agricultural applications."
    },
    {
        "id": "jd5045D4wd",
        "series": "d",
        "model": "JOHN DEERE 5045D 4WD",
        "driveType": '4WD',
        "engine": {
            "hpCategory": "45 HP",
            "engineCapacity": "2900 cc",
            "engineRatedRPM": "2100 RPM",
            "noOfCylinder": "3",
            "airFilter": "Dry type, Dual element",
            "coolingSystem": "Coolant cooled with overflow reservoir"
        },
        "transmission": {
            "clutchType": "Single / Dual Clutch",
            "transmissionType": "Collarshift",
            "gearLevelPosition": null,
            "speedMinMax": "2.83 – 30.92 kmph",
            "reverseSpeedMinMax": "3.71 – 13.43 kmph",
            "forwardGears": 8,
            "reverseGears": 4
        },
        "brakes": {
            "brakeType": "Oil Immersed",
            "turningRadiusWithBrake": "2900 mm"
        },
        "steering": {
            "steeringType": "Power Steering",
            "steeringAdjustment": "NO"
        },
        "pto": {
            "ptoType": "Independent 6 Splines",
            "ptoRPM": "540 @ 2100,1600 ERPM",
            "ptoPower": "38.20 HP"
        },
        "fuelCapacity": {
            "fuelTankCapacity": "60 litres"
        },
        "dimensionAndWeight": {
            "weight": "1810 KG",
            "wheelbase": "1970 MM",
            "overallLength": "3410 MM",
            "tractorWidth": "1810 MM",
            "groundClearance": "415 MM",
        },
        "liftingCapacity": {
            "liftCapacityInKg": "1600 kg",
            "3PointLinkage": "Category - II",
            "hydraulicControls": "ADDC"
        },
        "tyreSize": {
            "front": "6.0 x 16 / 8 x 18",
            "rear": "13.6 X 28",
        },
        "additionalFeature": {
            "driveType": "2WD / 4WD",
            "accessories": "Ballast weights / Canopy / Canopy holder / Draw bar / Wagon hitch"
        },
        "warranty": "5000 hr/5 year",
        "image": Model50451,
        "description": "John Deere 5045 is a power packed agricultural tractor with high back up torque and accelerated productivity towards all agricultural applications."
    },
    {
        "id": "jd5050D2wd",
        "series": "d",
        "model": "JOHN DEERE 5050D 2WD",
        "driveType": '2WD',
        "engine": {
            "hpCategory": "50 HP",
            "engineCapacity": "2900 cc",
            "engineRatedRPM": "2100 RPM",
            "noOfCylinder": "3",
            "airFilter": "Dry type, Dual element",
            "coolingSystem": "Coolant Cooled"
        },
        "transmission": {
            "clutchType": "Single / Dual Clutch",
            "transmissionType": "Collarshift",
            "gearLevelPosition": "Side Shift",
            "speedMinMax": "2.97 – 32.44 kmph",
            "reverseSpeedMinMax": "3.89 – 14.10 kmph",
            "forwardGears": 8,
            "reverseGears": 4
        },
        "brakes": {
            "brakeType": "Oil Immersed",
            "turningRadiusWithBrake": "2900 mm"
        },
        "steering": {
            "steeringType": "Mechanical / Power Steering",
            "steeringAdjustment": "NO"
        },
        "pto": {
            "ptoType": "6 Splines",
            "ptoRPM": "540",
            "ptoPower": "42.5 HP"
        },
        "fuelCapacity": {
            "fuelTankCapacity": "60 litres"
        },
        "dimensionAndWeight": {
            "weight": "	1870 KG",
            "wheelbase": "1970 MM",
            "overallLength": "3430 MM",
            "tractorWidth": "1830 MM",
            "groundClearance": "430 MM",
        },
        "liftingCapacity": {
            "liftCapacityInKg": "1600 kg",
            "3PointLinkage": "Category - II",
            "hydraulicControls": "ADDC"
        },
        "tyreSize": {
            "front": "6 X 16 / 7.5 X 16 / 8 X 18",
            "rear": "14.9 X 28 / 16.9 X 28",
        },
        "additionalFeature": {
            "driveType": "2WD / 4WD",
            "accessories": "Ballast weights / Canopy / Canopy holder / Draw bar / Wagon hitch",
            "other": "JD Link, Reverse PTO, Roll Over Protection System"
        },
        "warranty": "5000 hr/5 year",
        "image": Model5050,
        "description": "John Deere tractor 5050 widely connects with the farmers because of the tractors unmatched power, performance and productivity. Available in both 2WD and 4WD, this agricultural tractor is suitable for all heavy-duty farming applications."
    },
    {
        "id": "jd5050D4wd",
        "series": "d",
        "model": "JOHN DEERE 5050D 4WD",
        "driveType": '4WD',
        "engine": {
            "hpCategory": "50 HP",
            "engineCapacity": "2900 cc",
            "engineRatedRPM": "2100 RPM",
            "noOfCylinder": "3",
            "airFilter": "Dry type, Dual element",
            "coolingSystem": "Coolant Cooled"
        },
        "transmission": {
            "clutchType": "Single / Dual Clutch",
            "transmissionType": "Collarshift",
            "gearLevelPosition": "Side Shift",
            "speedMinMax": "2.97 – 32.44 kmph",
            "reverseSpeedMinMax": "3.89 – 14.10 kmph",
            "forwardGears": 8,
            "reverseGears": 4
        },
        "brakes": {
            "brakeType": "Oil Immersed",
            "turningRadiusWithBrake": "2900 mm"
        },
        "steering": {
            "steeringType": "Mechanical / Power Steering",
            "steeringAdjustment": "NO"
        },
        "pto": {
            "ptoType": "6 Splines",
            "ptoRPM": "540",
            "ptoPower": "42.5 HP"
        },
        "fuelCapacity": {
            "fuelTankCapacity": "60 litres"
        },
        "dimensionAndWeight": {
            "weight": "2010 KG",
            "wheelbase": "1970 MM",
            "overallLength": "3430 MM",
            "tractorWidth": "1810 MM",
            "groundClearance": "430 MM",
        },
        "liftingCapacity": {
            "liftCapacityInKg": "1600 kg",
            "3PointLinkage": "Category - II",
            "hydraulicControls": "ADDC"
        },
        "tyreSize": {
            "front": "6 X 16 / 7.5 X 16 / 8 X 18",
            "rear": "14.9 X 28 / 16.9 X 28",
        },
        "additionalFeature": {
            "driveType": "2WD / 4WD",
            "accessories": "Ballast weights / Canopy / Canopy holder / Draw bar / Wagon hitch",
            "other": "JD Link, Reverse PTO, Roll Over Protection System"
        },
        "warranty": "5000 hr/5 year",
        "image": Model5050,
        "description": "John Deere tractor 5050 widely connects with the farmers because of the tractors unmatched power, performance and productivity. Available in both 2WD and 4WD, this agricultural tractor is suitable for all heavy-duty farming applications."
    },
    {
        "id": "jd52102wd",
        "series": "e",
        "model": "JOHN DEERE 5210 2WD",
        "driveType": '2WD',
        "engine": {
            "hpCategory": "50 HP",
            "engineCapacity": "2900 cc",
            "engineRatedRPM": "2400 RPM",
            "noOfCylinder": "3",
            "airFilter": "Dry type, Dual element",
            "coolingSystem": "Coolant cooled with overflow reservoir"
        },
        "transmission": {
            "clutchType": "Dual Clutch",
            "transmissionType": "Collarshift",
            "gearLevelPosition": "Side Shift",
            "speedMinMax": "2.1 – 30.1 kmph",
            "reverseSpeedMinMax": "3.6 – 23.3 kmph",
            "forwardGears": 9,
            "reverseGears": 3
        },
        "brakes": {
            "brakeType": "Oil Immersed Disc Brakes",
            "turningRadiusWithBrake": "3181 mm"
        },
        "steering": {
            "steeringType": "Mechanical / Power Steering",
            "steeringAdjustment": "NO"
        },
        "pto": {
            "ptoType": "6 Splines",
            "ptoRPM": "540",
            "ptoPower": "42.5 HP"
        },
        "fuelCapacity": {
            "fuelTankCapacity": "68 litres"
        },
        "dimensionAndWeight": {
            "weight": "2105 KG",
            "wheelbase": "2050 MM",
            "overallLength": "3540 MM",
            "tractorWidth": "1820 MM",
            "groundClearance": "440 MM",
        },
        "liftingCapacity": {
            "liftCapacityInKg": "2000 kg",
            "3PointLinkage": "Category - II",
            "hydraulicControls": "ADDC"
        },
        "tyreSize": {
            "front": "6 X 16 / 7.5 X 16 / 6.5 X 20",
            "rear": "14.9 X 28 / 16.9 X 28",
        },
        "additionalFeature": {
            "driveType": "2WD / 4WD",
            "accessories": "Tool, Toplink, Canopy, Hook, Bumpher, Drawbar",
            "other": "Adjustable front axle, Heavy Duty Front Axle, Selective Control Valve (SCV), Reverse PTO (Standard + Reverse), Dual PTO (Standard + Economy), Synchromesh Transmission (TSS), Roll over protection system with deluxe seat & seat belt"
        },
        "warranty": "5000 hr/5 year",
        "image": Model52101,
        "description": "John Deere 5210 is a powerful 50 HP tractor, available in both 4WD and 2WD options. It is designed and equipped with power-packed features making it suitable for all tough and heavy duty applications."
    },
    {
        "id": "jd52104wd",
        "series": "e",
        "model": "JOHN DEERE 5210 4WD",
        "driveType": '4WD',
        "engine": {
            "hpCategory": "50 HP",
            "engineCapacity": "2900 cc",
            "engineRatedRPM": "2400 RPM",
            "noOfCylinder": "3",
            "airFilter": "Dry type, Dual element",
            "coolingSystem": "Coolant cooled with overflow reservoir"
        },
        "transmission": {
            "clutchType": "Dual Clutch",
            "transmissionType": "Collarshift",
            "gearLevelPosition": "Side Shift",
            "speedMinMax": "2.1 – 30.1 kmph",
            "reverseSpeedMinMax": "3.6 – 23.3 kmph",
            "forwardGears": 9,
            "reverseGears": 3
        },
        "brakes": {
            "brakeType": "Oil Immersed Disc Brakes",
            "turningRadiusWithBrake": "3181 mm"
        },
        "steering": {
            "steeringType": "Mechanical / Power Steering",
            "steeringAdjustment": "NO"
        },
        "pto": {
            "ptoType": "6 Splines",
            "ptoRPM": "540",
            "ptoPower": "42.5 HP"
        },
        "fuelCapacity": {
            "fuelTankCapacity": "68 litres"
        },
        "dimensionAndWeight": {
            "weight": "2105 KG",
            "wheelbase": "2050 MM",
            "overallLength": "3540 MM",
            "tractorWidth": "1820 MM",
            "groundClearance": "440 MM",
        },
        "liftingCapacity": {
            "liftCapacityInKg": "2000 kg",
            "3PointLinkage": "Category - II",
            "hydraulicControls": "ADDC"
        },
        "tyreSize": {
            "front": "6 X 16 / 7.5 X 16 / 6.5 X 20",
            "rear": "14.9 X 28 / 16.9 X 28",
        },
        "additionalFeature": {
            "driveType": "2WD / 4WD",
            "accessories": "Tool, Toplink, Canopy, Hook, Bumpher, Drawbar",
            "other": "Adjustable front axle, Heavy Duty Front Axle, Selective Control Valve (SCV), Reverse PTO (Standard + Reverse), Dual PTO (Standard + Economy), Synchromesh Transmission (TSS), Roll over protection system with deluxe seat & seat belt"
        },
        "warranty": "5000 hr/5 year",
        "image": Model5210,
        "description": "John Deere 5210 is a powerful 50 HP tractor, available in both 4WD and 2WD options. It is designed and equipped with power-packed features making it suitable for all tough and heavy duty applications."
    },
    {
        "id": "jd52104wd",
        "series": "e",
        "model": "JOHN DEERE 5210 4WD",
        "driveType": '4WD',
        "engine": {
            "hpCategory": "50 HP",
            "engineCapacity": "2900 cc",
            "engineRatedRPM": "2400 RPM",
            "noOfCylinder": "3",
            "airFilter": "Dry type, Dual element",
            "coolingSystem": "Coolant cooled with overflow reservoir"
        },
        "transmission": {
            "clutchType": "Dual Clutch",
            "transmissionType": "Collarshift",
            "gearLevelPosition": "Side Shift",
            "speedMinMax": "2.1 – 30.1 kmph",
            "reverseSpeedMinMax": "3.6 – 23.3 kmph",
            "forwardGears": 9,
            "reverseGears": 3
        },
        "brakes": {
            "brakeType": "Oil Immersed Disc Brakes",
            "turningRadiusWithBrake": "3181 mm"
        },
        "steering": {
            "steeringType": "Mechanical / Power Steering",
            "steeringAdjustment": "NO"
        },
        "pto": {
            "ptoType": "6 Splines",
            "ptoRPM": "540",
            "ptoPower": "42.5 HP"
        },
        "fuelCapacity": {
            "fuelTankCapacity": "68 litres"
        },
        "dimensionAndWeight": {
            "weight": "2105 KG",
            "wheelbase": "2050 MM",
            "overallLength": "3540 MM",
            "tractorWidth": "1820 MM",
            "groundClearance": "440 MM",
        },
        "liftingCapacity": {
            "liftCapacityInKg": "2000 kg",
            "3PointLinkage": "Category - II",
            "hydraulicControls": "ADDC"
        },
        "tyreSize": {
            "front": "6 X 16 / 7.5 X 16 / 6.5 X 20",
            "rear": "14.9 X 28 / 16.9 X 28",
        },
        "additionalFeature": {
            "driveType": "2WD / 4WD",
            "accessories": "Tool, Toplink, Canopy, Hook, Bumpher, Drawbar",
            "other": "Adjustable front axle, Heavy Duty Front Axle, Selective Control Valve (SCV), Reverse PTO (Standard + Reverse), Dual PTO (Standard + Economy), Synchromesh Transmission (TSS), Roll over protection system with deluxe seat & seat belt"
        },
        "warranty": "5000 hr/5 year",
        "image": Model5210,
        "description": "John Deere 5210 is a powerful 50 HP tractor, available in both 4WD and 2WD options. It is designed and equipped with power-packed features making it suitable for all tough and heavy duty applications."
    },
    {
        "id": "jd53102wd",
        "series": "e",
        "model": "JOHN DEERE 5310 2WD",
        "driveType": '2WD',
        "engine": {
            "hpCategory": "55 HP",
            "engineCapacity": "2900 cc",
            "engineRatedRPM": "2400 RPM",
            "noOfCylinder": "3",
            "airFilter": "Dry type, Dual element",
            "coolingSystem": "Coolant cooled"
        },
        "transmission": {
            "clutchType": "Wet Clutch",
            "transmissionType": "Collarshift",
            "gearLevelPosition": "Side Shift",
            "speedMinMax": "2.6 – 31.9 kmph",
            "reverseSpeedMinMax": "3.8 – 24.5 kmph",
            "forwardGears": 9,
            "reverseGears": 3
        },
        "brakes": {
            "brakeType": "Oil Immersed Disc Brakes",
            "turningRadiusWithBrake": "3150 mm"
        },
        "steering": {
            "steeringType": "Power Steering",
            "steeringAdjustment": "NO"
        },
        "pto": {
            "ptoType": "Independent 6 Splines",
            "ptoRPM": "540 @ 2376 ERPM",
            "ptoPower": "46.7 HP"
        },
        "fuelCapacity": {
            "fuelTankCapacity": "68 litres"
        },
        "dimensionAndWeight": {
            "weight": "2250 KG",
            "wheelbase": "2050 MM",
            "overallLength": "3530 MM",
            "tractorWidth": "1850 MM",
            "groundClearance": "435 MM",
        },
        "liftingCapacity": {
            "liftCapacityInKg": "2000 kg",
            "3PointLinkage": "Category - II",
            "hydraulicControls": "ADDC"
        },
        "tyreSize": {
            "front": "6.5 X 20 / 9.5 X 24",
            "rear": "16.9 X 28 / 13.6 X 28",
        },
        "additionalFeature": {
            "driveType": "2WD / 4WD",
            "accessories": "Tool, Toplink, Canopy, Hook, Bumpher, Drawbar",
            "other": "Best-in-class instrument panel, PowrReverser™ 12X12 transmission, A durable mechanical front-wheel drive (MFWD) axle increases traction in tough conditions, Tiltable steering column enhances operator comfort, Electrical quick raise and lower (EQRL) – Raise and lower implements in a flash"
        },
        "warranty": "5000 hr/5 year",
        "image": Model5310,
        "description": "John Deere 5310 is a 55 HP tractor designed for exceptional power and reliability. Its state-of-the-art technology makes it the best choice for wide range of applications in different soil conditions."
    },
    {
        "id": "jd53104wd",
        "series": "e",
        "model": "JOHN DEERE 5310 4WD",
        "driveType": '4WD',
        "engine": {
            "hpCategory": "55 HP",
            "engineCapacity": "2900 cc",
            "engineRatedRPM": "2400 RPM",
            "noOfCylinder": "3",
            "airFilter": "Dry type, Dual element",
            "coolingSystem": "Coolant cooled"
        },
        "transmission": {
            "clutchType": "Wet Clutch",
            "transmissionType": "Collarshift",
            "gearLevelPosition": "Side Shift",
            "speedMinMax": "2.6 – 31.9 kmph",
            "reverseSpeedMinMax": "3.8 – 24.5 kmph",
            "forwardGears": 9,
            "reverseGears": 3
        },
        "brakes": {
            "brakeType": "Oil Immersed Disc Brakes",
            "turningRadiusWithBrake": "3150 mm"
        },
        "steering": {
            "steeringType": "Power Steering",
            "steeringAdjustment": "NO"
        },
        "pto": {
            "ptoType": "Independent 6 Splines",
            "ptoRPM": "540 @ 2376 ERPM",
            "ptoPower": "46.7 HP"
        },
        "fuelCapacity": {
            "fuelTankCapacity": "68 litres"
        },
        "dimensionAndWeight": {
            "weight": "2410 KG",
            "wheelbase": "2050 MM",
            "overallLength": "3580 MM",
            "tractorWidth": "1875 MM",
            "groundClearance": "435 MM",
        },
        "liftingCapacity": {
            "liftCapacityInKg": "2000 kg",
            "3PointLinkage": "Category - II",
            "hydraulicControls": "ADDC"
        },
        "tyreSize": {
            "front": "6.5 X 20 / 9.5 X 24",
            "rear": "16.9 X 28 / 13.6 X 28",
        },
        "additionalFeature": {
            "driveType": "2WD / 4WD",
            "accessories": "Tool, Toplink, Canopy, Hook, Bumpher, Drawbar",
            "other": "Best-in-class instrument panel, PowrReverser™ 12X12 transmission, A durable mechanical front-wheel drive (MFWD) axle increases traction in tough conditions, Tiltable steering column enhances operator comfort, Electrical quick raise and lower (EQRL) – Raise and lower implements in a flash"
        },
        "warranty": "5000 hr/5 year",
        "image": Model53101,
        "description": "John Deere 5310 is a 55 HP tractor designed for exceptional power and reliability. Its state-of-the-art technology makes it the best choice for wide range of applications in different soil conditions."
    },
    {
        "id": "jd54052wd",
        "series": "e",
        "model": "JOHN DEERE 5405 2WD",
        "driveType": '2WD',
        "engine": {
            "hpCategory": "63 HP",
            "engineCapacity": "2900 cc",
            "engineRatedRPM": "2100 RPM",
            "noOfCylinder": "3",
            "airFilter": "Dry type, Dual element",
            "coolingSystem": "Coolant Cooled with overflow reservoir"
        },
        "transmission": {
            "clutchType": "Dual Clutch",
            "transmissionType": "Collarshift",
            "gearLevelPosition": "Side Shift",
            "speedMinMax": "2.0 - 32.6 kmph",
            "reverseSpeedMinMax": "3.5 - 22.9 kmph",
            "forwardGears": 12,
            "reverseGears": 4
        },
        "brakes": {
            "brakeType": "Oil Immersed Disc Brakes",
            "turningRadiusWithBrake": "3181 mm"
        },
        "steering": {
            "steeringType": "Power Steering",
            "steeringAdjustment": "YES"
        },
        "pto": {
            "ptoType": "Independent 6 Splines",
            "ptoRPM": "540, 540E",
            "ptoPower": "55 HP"
        },
        "fuelCapacity": {
            "fuelTankCapacity": "68 litres"
        },
        "dimensionAndWeight": {
            "weight": "2280 KG",
            "wheelbase": "2050 MM",
            "overallLength": "3515 MM",
            "tractorWidth": "1870 MM",
            "groundClearance": "425 MM",
        },
        "liftingCapacity": {
            "liftCapacityInKg": "2000 kg",
            "3PointLinkage": "Category - II",
            "hydraulicControls": "ADDC"
        },
        "tyreSize": {
            "front": "6.5 X 20",
            "rear": "16.9 X 30 / 16.9 X 28",
        },
        "additionalFeature": {
            "driveType": "2WD / 4WD",
            "accessories": "Ballast weights / Canopy / Canopy holder / Draw bar / Wagon hitch",
            "other": ""
        },
        "warranty": "5000 hr/5 year",
        "image": Model54051,
        "description": "John Deere 5405 is a powerful 63 HP tractor, available in both 2WD and 4WD options. Its powerful turbocharged engine delivers excellent power, making it easy to work with bigger implements and heavy-duty applications like rotavator, loader / dozer and harvester operations."
    },
    {
        "id": "jd54054wd",
        "series": "e",
        "model": "JOHN DEERE 5405 4WD",
        "driveType": '4WD',
        "engine": {
            "hpCategory": "63 HP",
            "engineCapacity": "2900 cc",
            "engineRatedRPM": "2100 RPM",
            "noOfCylinder": "3",
            "airFilter": "Dry type, Dual element",
            "coolingSystem": "Coolant Cooled with overflow reservoir"
        },
        "transmission": {
            "clutchType": "Dual Clutch",
            "transmissionType": "Collarshift",
            "gearLevelPosition": "Side Shift",
            "speedMinMax": "2.0 - 32.6 kmph",
            "reverseSpeedMinMax": "3.5 - 22.9 kmph",
            "forwardGears": 12,
            "reverseGears": 4
        },
        "brakes": {
            "brakeType": "Oil Immersed Disc Brakes",
            "turningRadiusWithBrake": "3181 mm"
        },
        "steering": {
            "steeringType": "Power Steering",
            "steeringAdjustment": "YES"
        },
        "pto": {
            "ptoType": "Independent 6 Splines",
            "ptoRPM": "540, 540E",
            "ptoPower": "55 HP"
        },
        "fuelCapacity": {
            "fuelTankCapacity": "68 litres"
        },
        "dimensionAndWeight": {
            "weight": "2570 KG",
            "wheelbase": "2050 MM",
            "overallLength": "3585 MM",
            "tractorWidth": "1910 MM",
            "groundClearance": "425 MM",
        },
        "liftingCapacity": {
            "liftCapacityInKg": "2000 kg",
            "3PointLinkage": "Category - II",
            "hydraulicControls": "ADDC"
        },
        "tyreSize": {
            "front": "11.2 X 24 / 9.5 X 24 ",
            "rear": "16.9 X 30 / 16.9 X 28",
        },
        "additionalFeature": {
            "driveType": "2WD / 4WD",
            "accessories": "Ballast weights / Canopy / Canopy holder / Draw bar / Wagon hitch",
            "other": ""
        },
        "warranty": "5000 hr/5 year",
        "image": Model5405,
        "description": "John Deere 5405 is a powerful 63 HP tractor, available in both 2WD and 4WD options. Its powerful turbocharged engine delivers excellent power, making it easy to work with bigger implements and heavy-duty applications like rotavator, loader / dozer and harvester operations."
    },
    {
        "id": "jd50754wd",
        "series": "e",
        "model": "JOHN DEERE 5075 4WD",
        "driveType": '4WD',
        "engine": {
            "hpCategory": "75 HP",
            "engineCapacity": "2900 cc",
            "engineRatedRPM": "2400 RPM",
            "noOfCylinder": "3",
            "airFilter": "Dry type, Dual element",
            "coolingSystem": "Liquid cooled"
        },
        "transmission": {
            "clutchType": "Dual Clutch",
            "transmissionType": "Synchromesh",
            "gearLevelPosition": "Side Shift",
            "speedMinMax": "2.2 – 28.3 kmph",
            "reverseSpeedMinMax": "3.7 – 24.2 kmph",
            "forwardGears": 9,
            "reverseGears": 3
        },
        "brakes": {
            "brakeType": "Oil Immersed Disc Brakes",
            "turningRadiusWithBrake": "3604 mm"
        },
        "steering": {
            "steeringType": "Power Steering",
            "steeringAdjustment": "NO"
        },
        "pto": {
            "ptoType": "6 Splines",
            "ptoRPM": "540 @ 2375 / 1705 ERPM",
            "ptoPower": "63.7 HP"
        },
        "fuelCapacity": {
            "fuelTankCapacity": "68 litres"
        },
        "dimensionAndWeight": {
            "weight": "2640 KG",
            "wheelbase": "2050 MM",
            "overallLength": "3625 MM",
            "tractorWidth": "1880 MM",
            "groundClearance": "460 MM",
        },
        "liftingCapacity": {
            "liftCapacityInKg": "2000 kg",
            "3PointLinkage": "Category - II",
            "hydraulicControls": "ADDC"
        },
        "tyreSize": {
            "front": "12.4 X 24 / 11.2 X 24",
            "rear": "18.4 X 30 / 16.9 X 30",
        },
        "additionalFeature": {
            "driveType": "4WD",
            "accessories": "Ballast weights / Canopy / Canopy holder / Draw bar / Wagon hitch",
            "other": "Car Type Engine ON/OFF, Mobile charger , Water Bottle Holder"
        },
        "warranty": "5000 hr/5 year",
        "image": Model5075,
        "description": "John Deere 5075 is a powerful 75 HP tractor equipped with advanced technology. Its powerful turbocharged engine delivers higher lift capacity and unmatched power is suitable for all heavy-duty applications."
    },
]

export default Tractor;