import "twin.macro";
import { Container, ContainerFluid } from "components/Wrapper.js";
import Header from "../components/headers/Header.js";
import Footer from "../components/footers/Footer.js";
import Service from "../components/Service.js";

export default function ServicePage(){
  return (
    <Container>
      <Header/>
      <ContainerFluid>
      <Service/>
      </ContainerFluid>
      <ContainerFluid>
      <Footer />
      </ContainerFluid>
    </Container>
  );
};
