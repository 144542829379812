import React, { useState, useEffect } from "react";
import "twin.macro";
import Gallery from "react-photo-gallery";
import { 
    Title,
    ImageContainer, 
    ImageOverlayLayer, 
    ImageOverlayText 
} from "./CommonComponent";
import ContactIng from '../assets/in_agriculture.jpg';
import { photos } from "../data/GalleryData.js";


export default function GalleryPage() {
    const [data, setData] = useState([]); 
    const [workshopData, setWorkshopData] = useState([]); 
    const [trainingData, setTrainingData] = useState([]); 
    const [ ceremonyData, setCeremonyData] = useState([]); 
    
    useEffect(()=>{
        const photoInfo = photos;
        return (
            setData(photoInfo.celebration),
            setWorkshopData(photoInfo.workshop),
            setTrainingData(photoInfo.training),
            setCeremonyData(photoInfo.ceremony)
        );
    },[])

    return (
        <>
            <div className="containerClass">
                <ImageContainer src={ContactIng} alt="about-us" />
                <ImageOverlayLayer>
                    <ImageOverlayText>Gallery</ImageOverlayText>
                </ImageOverlayLayer>
            </div>
            <div className="container" tw="lg:py-16 py-10">
                <Title className="color">JAN-JALLIKATTU(2017)</Title>
                <Gallery photos={data}  />
                <Title className="color py-3">WORKSHOP @ SALEM</Title>
                <Gallery photos={workshopData}  />
                <Title className="color py-3">TECHNICAL TRAINING @ SALEM On 05 Dec 2017</Title>
                <Gallery photos={trainingData}  />
                <Title className="color py-3">HOSUR BRANCH OPENING CEREMONY On 06 DEC 2017</Title>
                <Gallery photos={ceremonyData}  />
            </div>
        </> 
    ); 
};