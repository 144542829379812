import Image1 from "../assets/gallery/g-img1.jpg";
import Image2 from "../assets/gallery/g-img2.jpeg";
import Image3 from "../assets/gallery/g-img3.jpeg";
import Image4 from "../assets/gallery/g-img4.jpeg";
import Image5 from "../assets/gallery/g-img5.jpg";
import Image6 from "../assets/gallery/g-img6.jpg";
import Image7 from "../assets/gallery/g-img7.jpg";
import Image8 from "../assets/gallery/g-img8.jpg";
import Image9 from "../assets/gallery/g-img9.webp";
import Image10 from "../assets/gallery/g-img10.jpg";
import Image11 from "../assets/gallery/g-img11.jpg";
import Image12 from "../assets/gallery/g-img12.jpg";
import Image13 from "../assets/gallery/g-img13.jpg";
import Image14 from "../assets/gallery/g-img14.jpg";
import Image15 from "../assets/gallery/g-img15.jpg";
import Image16 from "../assets/gallery/g-img16.jpg";
import Image17 from "../assets/gallery/g-img17.jpg";
import Image18 from "../assets/gallery/g-img18.jpg";
import Image27 from "../assets/gallery/g-img27.jpg";
import Image28 from "../assets/gallery/g-img28.jpg";
import Image29 from "../assets/gallery/g-img29.jpg";
import Image30 from "../assets/gallery/g-img30.jpg";
import Image31 from "../assets/training/training1.jpeg";
import Image32 from "../assets/training/training2.jpeg";
import Image33 from "../assets/training/training3.jpeg";
import Image34 from "../assets/training/training4.jpeg";
import Image35 from "../assets/training/training5.jpeg";
import Image36 from "../assets/training/training6.jpeg";
import Image37 from "../assets/training/training7.jpeg";
import Image38 from "../assets/training/training8.jpeg";
import Image39 from "../assets/training/training9.jpeg";
import Image40 from "../assets/training/training10.jpeg";
import Image41 from "../assets/training/training11.jpeg";
import Image42 from "../assets/gallery/hosurbranchOpening/img1.jpeg";
import Image43 from "../assets/gallery/hosurbranchOpening/img2.jpeg";
import Image44 from "../assets/gallery/hosurbranchOpening/img3.jpeg";
import Image45 from "../assets/gallery/hosurbranchOpening/img4.jpeg";
import Image46 from "../assets/gallery/hosurbranchOpening/img5.jpeg";

export const  photos = {
  "celebration": [
    {
      src: Image2,
      width: 4,
      height: 3,
      loading: "lazy"
    },
    {
      src: Image1,
      width: 4,
      height: 3,
      loading: "lazy"
    },
    {
      src: Image3,
      width: 4,
      height: 3,
      loading: "lazy"
    },
    {
      src: Image4,
      width: 4,
      height: 3,
      loading: "lazy"
    },
    {
      src: Image5,
      width: 4,
      height: 3,
      loading: "lazy"
    },
    {
      src: Image6,
      width: 4,
      height: 3,
      loading: "lazy"
    },
    {
      src: Image7,
      width: 4,
      height: 3,
      loading: "lazy"
    },
    {
      src: Image8,
      width: 4,
      height: 3,
      loading: "lazy"
    },
    {
      src: Image9,
      width: 4,
      height: 3,
      loading: "lazy"
    },
    {
      src: Image10,
      width: 4,
      height: 3,
      loading: "lazy"
    },
    {
      src: Image11,
      width: 4,
      height: 3,
      loading: "lazy"
    },
    {
      src: Image12,
      width: 4,
      height: 3,
      loading: "lazy"
    },
    {
      src: Image13,
      width: 4,
      height: 3,
      loading: "lazy"
    },
    {
      src: Image14,
      width: 4,
      height: 3,
      loading: "lazy"
    },
    {
      src: Image15,
      width: 4,
      height: 3,
      loading: "lazy"
    },
    {
      src: Image16,
      width: 4,
      height: 3,
      loading: "lazy"
    },
    {
      src: Image17,
      width: 4,
      height: 3,
      loading: "lazy"
    },
    {
      src: Image18,
      width: 4,
      height: 3,
      loading: "lazy"
    }
  ],
  "workshop":[
    {
      src: Image27,
      width: 4,
      height: 3
    },
    {
      src: Image28,
      width: 4,
      height: 3
    },
    {
      src: Image29,
      width: 4,
      height: 3
    },
    {
      src: Image30,
      width: 4,
      height: 3
    }
  ],
  "training":[
    {
      src: Image31,
      width: 4,
      height: 3
    },
    {
      src: Image32,
      width: 4,
      height: 3
    },
    {
      src: Image33,
      width: 4,
      height: 3
    },
    {
      src: Image34,
      width: 4,
      height: 3
    },
    {
      src: Image35,
      width: 4,
      height: 3
    },
    {
      src: Image36,
      width: 4,
      height: 3
    },
    {
      src: Image37,
      width: 4,
      height: 3
    },
    {
      src: Image38,
      width: 4,
      height: 3
    },
    {
      src: Image39,
      width: 4,
      height: 3
    },
    {
      src: Image40,
      width: 4,
      height: 3
    },
    {
      src: Image41,
      width: 4,
      height: 3
    },
  ],
    "ceremony": [
      {
        src: Image42,
        width: 4,
        height: 3
      },
      {
        src: Image43,
        width: 4,
        height: 3
      },
      {
        src: Image44,
        width: 4,
        height: 3
      },
      {
        src: Image45,
        width: 4,
        height: 3
      },
      {
        src: Image46,
        width: 4,
        height: 3
      },
    ]
  };