import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import CloseToggle from "../../assets/navclose.svg";
import Toggle from "../../assets/navbar.svg";
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";
import logo from "../../assets/logo.png";
import '../../styles/globalStyles.css';

const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

const devices = {
  mobileS: `(min-width: ${sizes.mobileS})`,
  mobileM: `(min-width: ${sizes.mobileM})`,
  mobileL: `(min-width: ${sizes.mobileL})`,
  tablet: `(min-width: ${sizes.tablet})`,
  laptop: `(min-width: ${sizes.laptop})`,
  laptopL: `(min-width: ${sizes.laptopL})`,
  desktop: `(min-width: ${sizes.desktop})`,
};

const HeaderContainer = styled.div`
width: 100%;
z-index: 10;
height: 4.5rem;
position: fixed;
display: flex;
justify-content: space-between;
align-item: center;
padding-left: 2rem;
padding-right: 2rem;
@media ${devices.tablet}{
  padding-left: 4rem;
  padding-right: 4rem;
  height: 4rem;
}
`
const Nav = styled.div`
display: flex;
height: 100%; 
color: rgb(255 255 255) !important;
font-weight: bold;
font-size: 0.75rem;
@media(max-width:768px){
  font-size: 0.875rem;
  justify-content: space-evenly;
}`;

const NavList = styled.li`
list-style-type:none !important;
display: flex;
align-items: center;
flex-direction: column;
justify-content: center;
cursor:pointer;
padding-top: 10px;
padding-bottom: 10px;
width: 100%;
font-size: inherit !important;
@media ${devices.tablet}{
  width: inherit;
}
`;

const NavListItem = styled.div`
display: ${props => props.showList ? 'block' : 'none'};
min-width: 100%;
z-index: 10;
color: white;
@media ${devices.tablet}{
  top: 64px;
  right: 258px;
  position: absolute;
  min-width: 163px;
  background: rgba(0, 0, 0, 0.589);
  -webkit-backdrop-filter: blur(5px);
  -moz-backdrop-filter: blur(5px);
  -o-backdrop-filter: blur(5px);
  -ms-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  ${NavList}:hover & {
    display: block;
  }
}
`;

const DropDownList = styled.li`
display: flex;
&:hover{
  transition: all 0.3s linear;
  background-color: #fce94f !important;
  color: black
}
`
const DropNavLink = styled(Link)`
width:100% !important;
padding: 15px; 
text-decoration: none
`
const NavLink = styled(Link)`
height: 100% !important;
display:flex;
width: 100%;
justify-content: center;
align-items: center;
padding-left: 1.5rem;
padding-right: 1.5rem;
text-decoration: none !important;
@media ${devices.tablet}{
  width: auto;
} `;

const MobileNavLinksContainer = styled.nav`
@media(min-width:768px){
  display: none !important; 
}
display:flex;
color: #000 !important;
flex: 1 1 0%;
justify-content: space-between;
align-item: center`;

const NavToggle = styled.button`
  z-index:20;
  background: none;
  border: none;
  tansition: linear;
  transition-duration: 300ms;
  @media ${devices.tablet}{
    display: hidden;
  }
`;

const LogoLink = styled(Link)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0! py-2`};
  img {
    ${tw`mr-3`}
  }
`;

const MobileNavLinks = motion(styled.div`
  inset: 0px;
  z-index: 10;
  height: 97vh !important;
  background: rgba(0, 0, 0, 1);
  -webkit-backdrop-filter: blur(5px);
  -moz-backdrop-filter: blur(5px);
  -o-backdrop-filter: blur(5px);
  -ms-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  position: fixed;
${tw`lg:hidden flex items-center mx-2  my-3 text-center `}
  ${Nav} {
    ${tw`flex flex-col items-center`}
  }
`);

const ToggleImg = styled.img`
width: 48px;
height: 48px;
`
const CloseToggleImg = styled.img`
width: 48px;
height: 48px;
padding-left: 5px;
padding-top: 15px;
`
const DesktopNavLinks = tw.nav`hidden lg:flex flex-1 h-full justify-between items-center`;

export default function Header(props, { className, collapseBreakpointClass = "lg" }) {
  const [activeTab] = useState(
    props.isActiveTab !== undefined && props.isActiveTab
      ? props.isActiveTab
      : "1"
  );
  const [showList, setShowList] = useState(false);

  const DefaultLinks = () => (
    <Nav key={1} >
      <NavList className={activeTab === "1" ? "active_nav_bar" : "non_active_nav_bar"} >
        <NavLink to="/">
          HOME
        </NavLink>
      </NavList>
      <NavList className={activeTab === "2" ? "active_nav_bar" : "non_active_nav_bar"}>
        <NavLink to="/overview">
          OVERVIEW
        </NavLink>
      </NavList>
      <NavList tw='px-6' className={activeTab === "3" ? "active_nav_bar" : "non_active_nav_bar"} onClick={() => { setShowList(!showList) }}>
        <div>PRODUCT { showList ? <i className="fa fa-caret-up fa-lg mx-1" /> : <i className="fa fa-caret-down fa-lg mx-1" />}</div>
        <NavListItem showList={showList}>
          <DropDownList>
            <DropNavLink to="/tractors" className="pt-4">
              TRACTOR
            </DropNavLink>
          </DropDownList>
          <DropDownList>
            <DropNavLink to="/implement">
              IMPLEMENTS
            </DropNavLink>
          </DropDownList>
          <DropDownList>
            <DropNavLink to="/harvestor">
              HARVESTOR
            </DropNavLink>
          </DropDownList>
        </NavListItem>
      </NavList>
      <NavList className={activeTab === "4" ? "active_nav_bar" : "non_active_nav_bar"} >
        <NavLink to="/about" >
          ABOUT US
        </NavLink>
      </NavList>
      <NavList className={activeTab === "5" ? "active_nav_bar" : "non_active_nav_bar"}>
        <NavLink to="/contact" >
          CONTACT US
        </NavLink>
      </NavList>
    </Nav>
  );

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];
  const DefaultLogoLink = () => (
    <LogoLink to="/">
      <img src={logo} alt="Races" width="135px" height="100%" />
    </LogoLink>
  );
  return (
    <>
      <HeaderContainer className={className || "headerClass"} isActiveTab="1">
        <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
          <DefaultLogoLink />
          <DefaultLinks />
        </DesktopNavLinks>
        <MobileNavLinksContainer
          css={collapseBreakpointCss.mobileNavLinksContainer}
        >
          <DefaultLogoLink />
          <MobileNavLinks
            initial={{ x: "150%", display: "none" }}
            animate={animation}
            css={collapseBreakpointCss.mobileNavLinks}
          >
            <DefaultLinks />
          </MobileNavLinks>
          <NavToggle
            onClick={toggleNavbar}
            className={showNavLinks ? "open" : "closed"}
          >
            {showNavLinks ? (
              <span>
                <CloseToggleImg src={CloseToggle} alt="toogle" />
              </span>
            ) : (
              <span>
                <ToggleImg src={Toggle} alt="toogle" />
              </span>
            )}
          </NavToggle>
        </MobileNavLinksContainer>
      </HeaderContainer>
    </>
  );
};

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};