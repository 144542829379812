import React from "react";
import { Container, ContainerFluid } from "./components/Wrapper.js";
import ImageSlider from "./components/Carousel.js";
import Header from "./components/headers/Header.js";
import Footer from "./components/footers/Footer.js";
import Home from "./components/Home.js";
import "aos/dist/aos.css";

export default function MainLandingPage() {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
  window.gtag("js", new Date());
  window.gtag("config", "UA-45799926-9");
  return (
    <Container>
      <Header />
      <ContainerFluid>
        <ImageSlider />
      </ContainerFluid>
      <ContainerFluid>
        <Home />
      </ContainerFluid>
      <ContainerFluid>
        <Footer />
      </ContainerFluid>
    </Container>
  );
};
