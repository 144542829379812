import React from "react";
import { Container, ContainerFluid } from "../components/Wrapper.js";
import Header from "../components/headers/Header.js";
import Footer from "../components/footers/Footer.js";
import Harvestors from "../components/Harvestor.js";

export default function Harvestor(){
  return (
    <Container>
      <Header isActiveTab="3" />
      <ContainerFluid>
        <Harvestors />
      </ContainerFluid>
      <ContainerFluid>
        <Footer />
      </ContainerFluid>
    </Container>
  );
};