import React from "react";
import styled from "styled-components";

const CardImageContainer = styled.img`
width: 100%;
height: 100%;
object-fit: cover !important;`;

const Flip = styled.div`
transition: 0.6s;
-webkit-transition: 0.6s;
transform-style: preserve-3d;
-webkit-transform-style: preserve-3d;
position: relative;
`
const FlipperContainer = styled.div`
perspective: 1000;
-webkit-perspective: 1000;
background: transparent;
width: 100% !important;
height: 240px;
&:hover ${Flip}{
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
}
`
const FrontSide = styled.div`
backface-visibility: hidden;
-webkit-backface-visibility: hidden;
position: absolute;
top: 0;
width: 100% !important;
height: 240px !important;
left: 0;
z-index: 2;
`

const BackSide = styled.div`
transform: rotateY(180deg);
-webkit-transform: rotateY(180deg);
background: #c7081b !important;
text-align: center;
backface-visibility: hidden;
-webkit-backface-visibility: hidden;
position: absolute;
width: 100% !important;
height: ${props => !!props.iFrameMap ? '100%' : ''};
top: 0;
left: 0;
`

export default function Flipper(props) {
    return (
        <FlipperContainer>
            <Flip>
                <FrontSide>
                    <CardImageContainer src={props.image} className='image-fluid' />
                </FrontSide>
                <BackSide iFrameMap={props.iFrameMap}>
                    <iframe title={props.title} src={props.iFrameMap} height="240px" width="100%" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                </BackSide>
            </Flip>
        </FlipperContainer>
    );
};
