import Branch1 from "../assets/salem1.png";
import Branch2 from "../assets/salem2.png";
import Branch3 from "../assets/kolathur.jpg";
import Branch4 from "../assets/attur2.png";
import Branch5 from "../assets/krishnagiri.png";
import Branch6 from "../assets/uthangarai.jpg";
import Branch7 from "../assets/hosur.png";
import Branch8 from "../assets/d-kotta.png";
import Branch9 from "../assets/kallakurichi.jpg";
import Branch10 from "../assets/moongil.jpeg";
import Branch11 from "../assets/thirukoilur.jpeg";
import Branch12 from "../assets/perambalur.jpeg";
import Branch13 from "../assets/krishnapuram.jpg";

const branch = [
    {
        "id": "1",
        "company": "RASI AGRICULTURE EQUIPMENTS (RACES)",
        "branch": "Salem - Head Office",
        "isHeadOffice": true,
        "address": `RACES 
                    146/1,Amaravathy Nagar,
                    Banglore Highway,
                    Mamangam,Salem,Tamil Nadu,
                    636 302`,
        "phone": "+91-96552 24481",
        "mail": "partsslmjd@racesonline.in",
        "coOrdinates": {
            "latitude": 11.6935857,
            "longitude": 78.1098153
        },
        "image": Branch1,
        "iFrameMap": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2843.624327928722!2d78.10744647211301!3d11.693847786768119!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3babf06abf8726df%3A0xb141806aa091530e!2sRACES%20John%20Deere!5e0!3m2!1sen!2sin!4v1672984694955!5m2!1sen!2sin"

    },
    {
        "id": "2",
        "company": "RASI AGRICULTURE EQUIPMENTS (RACES)",
        "branch": "Salem - Workshop",
        "isHeadOffice": true,
        "address": `RACES 
                    I/164B,Bhavaanur Medu,
                    Omalur Main Road,Near Puliampatty Village,
                    Kottagoundanpatty,
                    Salem,Tamil Nadu, 635 011`,
        "phone": "+91-98438 38383",
        "mail": "partsslmjd@racesonline.in",
        "coOrdinates": {
            "latitude": 11.7270183,
            "longitude": 78.0638915
        },
        "image": Branch2,
        "iFrameMap": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3906.543932662428!2d78.063692!3d11.72668!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1659fc9202daaf27!2sJohn%20Deere%20tractor!5e0!3m2!1sen!2sin!4v1673001985527!5m2!1sen!2sin"
    },
    {
        "id": "3",
        "company": "RASI AGRICULTURE EQUIPMENTS (RACES)",
        "branch": "Kolathur",
        "isHeadOffice": false,
        "address": `RACES 
                    Indian Oil Petrol Bunk Opp,
                    Chinna Mettur, 
                    Kolathur Mettur,
                    Tamil Nadu, 636 404`,
        "phone": "+91-70944 55293",
        "mail": "partsslmjd@racesonline.in",
        "coOrdinates": {
            "latitude": 11.837987,
            "longitude": 77.751415
        },
        "image": Branch3,
        "iFrameMap": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1952.4821579967793!2d77.75103949073448!3d11.83777173661419!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x89fe54f91e44a722!2sRaces%20John%20Deere!5e0!3m2!1sen!2sin!4v1673009394699!5m2!1sen!2sin"
    },
    {
        "id": "4",
        "company": "RASI AGRICULTURE EQUIPMENTS (RACES)",
        "branch": "Attur",
        "isHeadOffice": false,
        "address": `RACES 
                    3/422,Cuddalore Main Road,
                    Bharathipuram,
                    Thulukannoor Road, Attur,
                    Salem,Tamil Nadu, 636 141`,
        "phone": "+91-96552 24491",
        "mail": "racesattur@yahoo.in",
        "coOrdinates": {
            "latitude": 11.591739,
            "longitude": 78.623939
        },
        "image": Branch4,
        "iFrameMap": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7594.377276086261!2d78.62320850257606!3d11.590894006105485!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2bf2797d71cd09dd!2sRACES%20John%20deere!5e0!3m2!1sen!2sin!4v1673009551581!5m2!1sen!2sin"
    },
    {
        "id": "5",
        "company": "RASI AGRICULTURE EQUIPMENTS (RACES)",
        "branch": "Krishnagiri",
        "isHeadOffice": false,
        "address": `RACES 
                    3/469, SIT Complex,
                    Periyar Nagar, 
                    Old RTO Office(Opp.), 
                    Krishnagiri, 
                    Tamil Nadu 635 001`,
        "phone": "+91-97877 27303",
        "mail": "jdkirishnagiri@yahoo.com",
        "coOrdinates": {
            "latitude": 12.523307,
            "longitude": 78.210698
        },
        "image": Branch5,
        "iFrameMap": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d841.4168789171423!2d78.21051824704838!3d12.523107730291162!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf445f255904d42a7!2sRACES%20JOHNDEERE!5e0!3m2!1sen!2sin!4v1674886464499!5m2!1sen!2sin"
    },
    {
        "id": "6",
        "company": "RASI AGRICULTURE EQUIPMENTS (RACES)",
        "branch": "Uthangarai",
        "isHeadOffice": false,
        "address": `RACES
                    Thirupattur Main Road,
                    Vadivel Petrol Bunk,
                    Uthangarai, Tamil Nadu 635 207`,
        "phone": "+91-97877 27153",
        "mail": "partsslmjd@racesonline.in",
        "coOrdinates": {
            "latitude": 12.277412,
            "longitude": 78.535345
        },
        "image": Branch6,
        "iFrameMap": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2731.2849671671497!2d78.53456881390763!3d12.277430773815713!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x84daa040a45a1afe!2sRACES%20JOHNDEERE!5e0!3m2!1sen!2sin!4v1674887862249!5m2!1sen!2sin"
    },
    {
        "id": "7",
        "company": "RASI AGRICULTURE EQUIPMENTS (RACES)",
        "branch": "Hosur",
        "isHeadOffice": false,
        "address": `RACES
                    6/4, Maheshwari Complex,
                    Opp. Sub Treasury,
                    Rayakottai Road,
                    Hosur, Tamil Nadu 635109`,
        "phone": "+91-97877 27153",
        "mail": "partsslmjd@racesonline.in",
        "coOrdinates": {
            "latitude": 12.716942,
            "longitude": 77.839333
        },
        "image": Branch7,
        "iFrameMap": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3891.9460587569065!2d77.839333!3d12.716942!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7f6f9981dc98fbd3!2sRACES%20Johndeere!5e0!3m2!1sen!2sin!4v1673002509238!5m2!1sen!2sin"
    },
    {
        "id": "8",
        "company": "RASI AGRICULTURE EQUIPMENTS (RACES)",
        "branch": "Denkanikottai",
        "isHeadOffice": false,
        "address": `RACES
                    Bajja Palli Bus Stop,
                    Thali Main Road,
                    Denkanikottai, Tamil Nadu`,
        "phone": "+91-96552 24482",
        "mail": " jdkrishnagiri@yahoo.com",
        "coOrdinates": {
            "latitude": 12.532441,
            "longitude": 77.779655
        },
        "image": Branch8,
        "iFrameMap": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1947.3770621433978!2d77.779655!3d12.532441!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc9152a21ef53c0e1!2sRACES%20JohnDeere!5e0!3m2!1sen!2sin!4v1674887704941!5m2!1sen!2sin"
    },
    {
        "id": "9",
        "company": "RASI AGRICULTURE EQUIPMENTS (RACES)",
        "branch": "Kallakurichi",
        "isHeadOffice": false,
        "address": `RACES
                    PSK Flats,
                    kallakurichi - chennai Bye pass(NH-79), 
                    Emapper,Kallakurichi,
                    Tamil Nadu 606202`,
        "phone": "+91-76399 00011",
        "mail": "sales.kk@racesonline.in",
        "coOrdinates": {
            "latitude": 11.71991,
            "longitude": 78.958643
        },
        "image": Branch9,
        "iFrameMap": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1953.3173363724106!2d78.95755585592805!3d11.72026687575486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bab6711fb0bcbcd%3A0xd5f153bb1530d364!2sRasi%20AgriCulture%20Equipment%E2%80%99S%20(%20RACES%20JOHNDEERE%20)!5e0!3m2!1sen!2sin!4v1674893458123!5m2!1sen!2sin"
    },
    {
        "id": "10",
        "company": "RASI AGRICULTURE EQUIPMENTS (RACES)",
        "branch": "Moongilthuraipattu",
        "isHeadOffice": false,
        "address": `RACES
                    Thiruvannamalai Road,
                    Moongilthuraipattu,
                    SanKarapuram(Tk)
                    Kallakurichi(Dt),
                    Tamil Nadu 605702`,
        "phone": "+91-70944 00022",
        "mail": " jdkrishnagiri@yahoo.com",
        "coOrdinates": {
            "latitude": 12.0485812,
            "longitude": 78.9661323
        },
        "image": Branch10,
        "iFrameMap": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4934.903611472873!2d78.96626909347054!3d12.04881007440576!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bac97f6b6d3e13b%3A0x6ed5e26d2f132e35!2sRACES%20JOHNDEERE!5e0!3m2!1sen!2sin!4v1678175290912!5m2!1sen!2sin"
    },
    {
        "id": "11",
        "company": "RASI AGRICULTURE EQUIPMENTS (RACES)",
        "branch": "ThiruKoilur",
        "isHeadOffice": false,
        "address": `RACES
                    Near Sivan Kovil,
                    Mookambikai Nagar,
                    Manampoondi,
                    Thiruvannamalai Main Road,
                    Thirukoilur 605757`,
        "phone": "+91-70944 00011",
        "mail": " jdkrishnagiri@yahoo.com",
        "coOrdinates": {
            "latitude": 11.978438,
            "longitude": 79.202813
        },
        "image": Branch11,
        "iFrameMap": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d975.7357790697696!2d79.202813!3d11.978438!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bacafd6cb08fc6b%3A0x2966c06e8e40c2c5!2sRACES%20JohnDeere!5e0!3m2!1sen!2sin!4v1678085990914!5m2!1sen!2sin"
    },
    {
        "id": "12",
        "company": "RASI AGRICULTURE EQUIPMENTS (RACES)",
        "branch": "Perambalur",
        "isHeadOffice": false,
        "address": `RACES
                    2/316A-Sundara Nagar,
                    Near Essar Petrol Bank, 
                    Ariyalur Main Road,
                    Kavulpalayan,
                    Perambalur -621220`,
        "phone": "+91-70944 00044",
        "mail": " jdkrishnagiri@yahoo.com",
        "coOrdinates": {
            "latitude": 11.241821,
            "longitude": 78.905657
        },
        "image": Branch12,
        "iFrameMap": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3913.2689786273977!2d78.90563179999995!3d11.241612900000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bab1b1a4e1323bd%3A0xac4079a2d1a05dab!2sRasi%20AgriCulture%20Equipment%E2%80%99S%20(RACES%20Johndeere)!5e0!3m2!1sen!2sin!4v1674896725505!5m2!1sen!2sin"
    },
    {
        "id": "13",
        "company": "RASI AGRICULTURE EQUIPMENTS (RACES)",
        "branch": "Krishnapuram",
        "isHeadOffice": false,
        "address": `RACES
                    Attur Main Road,
                    Opp HP petrol Bunk, 
                    Krishnapuram(Po),
                    Veppanthattai(Tk),
                    Perambalur(Dt) -621116`,
        "phone": "+91-70944 00033",
        "mail": " jdkrishnagiri@yahoo.com",
        "coOrdinates": {
            "latitude": 11.3721054,
            "longitude": 78.7934006
        },
        "image": Branch13,
        "iFrameMap": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2994.8783510216954!2d78.79302511960698!3d11.3720878847844!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xae4406fb7f023d27!2sRACES%20JOHNDEERE!5e0!3m2!1sen!2sin!4v1674898308203!5m2!1sen!2sin"
    },
];

export default branch;