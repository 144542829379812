import React from "react";
import { Container, ContainerFluid } from "../components/Wrapper.js";
import Header from "../components/headers/Header.js";
import Footer from "../components/footers/Footer.js";
import Map from "../components/Map.js";

export default function MapPage() {
    return (
        <Container >
            <Header />
            <ContainerFluid>
                <Map />
            </ContainerFluid>
            <ContainerFluid>
                <Footer />
            </ContainerFluid>
        </Container>
    );
};