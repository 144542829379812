import React from "react";
import { Container, ContainerFluid } from "../components/Wrapper.js";
import Header from "../components/headers/Header.js";
import Footer from "../components/footers/Footer.js";
import OverViewPage from "../components/Overview.js";

export default function OverView() {
    return (
        <Container>
            <Header isActiveTab="2" />
            <ContainerFluid>
                <OverViewPage />
            </ContainerFluid>
            <ContainerFluid>
                <Footer />
            </ContainerFluid>
        </Container>
    );
};