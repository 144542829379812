import React from "react";
import { Container, ContainerFluid } from "../components/Wrapper.js";
import Header from "../components/headers/Header.js";
import Footer from "../components/footers/Footer.js";
import AboutUs from "../components/AboutUs.js";

export default function About() {
  return (
    <Container >
      <Header isActiveTab="4" />
      <ContainerFluid>
        <AboutUs />
      </ContainerFluid>
      <ContainerFluid>
        <Footer />
      </ContainerFluid>
    </Container>
  );
};
