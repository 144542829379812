import React from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { HelmetProvider } from 'react-helmet-async';
import { Routes, Route } from "react-router-dom";
import FindUs from "./components/FindUs";
import Gallery from "./pages/GalleryPage.js";
import Overview from "./pages/Overview.js";
import About from "./pages/About.js";
import Contact from "./pages/Contact.js";
import Tractor from "./pages/TractorPage.js";
import Implement from "./pages/ImplemetPage.js";
import Harvestor from "./pages/HarvestorPage.js";
import CseriesPage from "./pages/CseriesPage.js";
import DseriesPage from "./pages/DseriesPage.js";
import EseriesPage from "./pages/EseriesPage.js";
import MainLandingPage from "./MainLandingPage.js";
import MapPage from "./pages/MapPage.js";
import Service from "./pages/ServicePage.js";
import ScrollToTop from "./components/ScrollTop";
import TopperScroll from "./components/TopperScroll.js";
import "./styles/globalStyles.css";

export default function App() {
  AOS.init();
  AOS.refresh();
  const helmetContext = {};
  
  return (
    <React.Fragment>
      <HelmetProvider context={helmetContext}>
        <React.Fragment>
          <ScrollToTop />
        </React.Fragment>
        <React.Fragment>
          <TopperScroll />
        </React.Fragment>
        <React.Fragment>
          <FindUs />
        </React.Fragment>
        <Routes>
          <Route path="/service" element={<Service />} />

          <Route path="/about" element={<About />} />

          <Route path="/contact" element={<Contact />} />

          <Route path="/map" element={<MapPage />} />

          <Route path="/overview" element={<Overview />} />

          <Route path="/tractors" element={<Tractor />} />

          <Route path="/implement" element={<Implement />} />

          <Route path="/harvestor" element={<Harvestor />} />

          <Route path="/en-series" element={<CseriesPage />} />

          <Route path="/d-series" element={<DseriesPage />} />

          <Route path="/e-series" element={<EseriesPage />} />

          <Route path="/gallery" element={<Gallery />} />

          <Route path="/" element={<MainLandingPage />} />

          <Route path="*" element={<MainLandingPage />} />
        </Routes>
      </HelmetProvider>
      {/* </BrowserRouter> */}
    </React.Fragment>
  );
}
