import React, { useState } from "react";
// import { Link } from "react-router-dom";
import "twin.macro";
import styled from "styled-components";
import {
    Title,
    Description,
    ImageContainer,
    ImageOverlayLayer,
    ImageOverlayText,
    Container,
    CardContainer,
    EnquiryButton
} from './CommonComponent.js';
import SEO from "./Seo.js";
import EnquiryModal from "./EnquiryModal.js";
import ContactIng from '../assets/in_agriculture.jpg';
import Ratoon from "../assets/tractor/ratoon.webp";
import Rotary from "../assets/tractor/rotary.webp";
import Cultivator from "../assets/tractor/cultivator.webp";
import Vaccum from "../assets/tractor/vaccum.webp";
import Driller from "../assets/tractor/driller.webp";
import Paddy from "../assets/tractor/paddy.webp";

const Image = styled.img`
width:100%;
`
export default function Implements() {

    const [showModal, setShowModal] = useState(false);

    const handleModalChange = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <SEO
                title='Implements - Races'
                description='John Deere Implements'
                name='Races'
                type='article' />
            <div className="containerClass">
                <ImageContainer src={ContactIng} alt="about-us" />
                <ImageOverlayLayer>
                    <ImageOverlayText>Implements</ImageOverlayText>
                </ImageOverlayLayer>
            </div>
            <div className="container" tw="lg:py-16 py-10">
                <div className="row">
                    <Container className="col-md-4">
                        <CardContainer>
                            <Image src={Cultivator} alt="cultivator" />
                            <div className="p-2">
                                <Title>Cultivator</Title>
                                <Description>GreenSystem Cultivator is useful in land preparation. The cultivator variants cater to all kinds of soils, ranging from soft and medium soil to stony and rough soil. This farm equipment suits all kinds of crops.</Description>
                                <EnquiryButton>
                                    <button key={Math.random().toString()} onClick={handleModalChange}><span> Enquiry on Whatsapp </span> </button>
                                </EnquiryButton>
                            </div>
                        </CardContainer>
                    </Container>
                    <Container className="col-md-4">
                        <CardContainer>
                            <Image src={Ratoon} alt="ratoon" />
                            <div className="p-2">
                                <Title>Ratoon Manager</Title>
                                <Description>Cuts the old sugarcane root system leading to better growth of ratoon crop & establishment of new root system.</Description>
                                <EnquiryButton>
                                    <button key={Math.random().toString()} onClick={handleModalChange}><span> Enquiry on Whatsapp </span> </button>
                                </EnquiryButton>
                            </div>
                        </CardContainer>
                    </Container>
                    <Container className="col-md-4">
                        <CardContainer>
                            <Image src={Rotary} alt="rotary" />
                            <div className="p-2">
                                <Title>John Deere Rotary Tiller</Title>
                                <Description>John Deere rotary tiller is reliable machine for dry and wet land cultivation and provides high quality seed bed preparation and residue incorporation.</Description>
                                <EnquiryButton>
                                    <button key={Math.random().toString()} onClick={handleModalChange}><span> Enquiry on Whatsapp </span> </button>
                                </EnquiryButton>
                            </div>
                        </CardContainer>
                    </Container>
                    <Container className="col-md-4">
                        <CardContainer>
                            <Image src={Driller} alt="seed-cum-fertilizer-drill" />
                            <div className="p-2">
                                <Title>Seed Cum Fertilizer Drill</Title>
                                <Description>Green System offers a variety of high-quality tillage implements to get the job done right.</Description>
                                <EnquiryButton>
                                    <button key={Math.random().toString()} onClick={handleModalChange}><span> Enquiry on Whatsapp </span> </button>
                                </EnquiryButton>
                            </div>
                        </CardContainer>
                    </Container>
                    <Container className="col-md-4">
                        <CardContainer>
                            <Image src={Vaccum} />
                            <div className="p-2">
                                <Title>Multi crop Vacuum Planter</Title>
                                <Description>GreenSystem Multi-crop Vacuum Planter assists in Sowing and Planting. It provides high accuracy in the sowing of multiple crops like Cotton, Corn, Soybean and Grains. This farm equipment is suitable for all types of soils.</Description>
                                <EnquiryButton>
                                    <button key={Math.random().toString()} onClick={handleModalChange}><span> Enquiry on Whatsapp </span> </button>
                                </EnquiryButton>
                            </div>
                        </CardContainer>
                    </Container>
                    <Container className="col-md-4">
                        <CardContainer>
                            <Image src={Paddy} />
                            <div className="p-2">
                                <Title>Paddy Special Rotary Tiller</Title>
                                <Description>Paddy Special Rotary Tiller is useful in land preparation. This light weight puddling special Rotary Tiller is efficient in preparing the seedbed for transplanting of paddy. This farm equipment is best suited for Paddy crops.</Description>
                                <EnquiryButton>
                                    <button key={Math.random().toString()} onClick={handleModalChange}><span> Enquiry on Whatsapp </span> </button>
                                </EnquiryButton>
                            </div>
                        </CardContainer>
                    </Container>
                </div>
            </div>
            {showModal && <EnquiryModal openModal={showModal} closeModal={handleCloseModal} />}
        </>
    );
};