import React, { useState } from "react";
import "twin.macro";
import {
    Container,
    CardContainer,
    ImageOverlayLayer,
    Title,
    Image,
    ImageOverlayText,
    ImageContainer,
    Description,
    EnquiryButton
} from "./CommonComponent.js";
import ContactIng from '../assets/in_agriculture.jpg';
import data from "../data/ProductData.js";
import EnquiryModal from "./EnquiryModal.js";

export default function Dseries() {

    //eslint-disable-next-line
    const [productData, setProductData] = useState(data);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState('');

    const handleModalChange = (item) => {
        setShowModal(true);
        setModalData(item.model);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <div className="containerClass">
                <ImageContainer src={ContactIng} alt="about-us" />
                <ImageOverlayLayer>
                    <ImageOverlayText>D-Series</ImageOverlayText>
                </ImageOverlayLayer>
            </div>
            <div className="container" tw="lg:px-5 py-16 py-10">
                <div className="row">
                    {productData.map(item => {
                        return (
                            <>
                                {(() => {
                                    if (item.series === 'd')
                                        return (
                                            <Container className="col-md-4">
                                                <CardContainer className="p-3" key={item.id}>
                                                    <Image src={item.image} alt="c-series" />
                                                    <div className="p-2">
                                                        <Title className="color py-2">{item.model}</Title>
                                                        <Description>{item.description}</Description>
                                                        <div className="row">
                                                            <div className="col">
                                                                <h6>
                                                                    HP : {item.engine.hpCategory}
                                                                </h6>
                                                            </div>
                                                            <div className="col">
                                                                <h6>
                                                                    Drive: {item.driveType}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                        <EnquiryButton>
                                                            <button key={item.id} onClick={() => handleModalChange(item)}><span> Enquiry on Whatsapp </span> </button>
                                                        </EnquiryButton>
                                                    </div>
                                                </CardContainer>
                                            </Container>
                                        );
                                })()}
                            </>
                        );
                    })}
                </div>
            </div>
            {showModal && <EnquiryModal openModal={showModal} closeModal={handleCloseModal} modalData={modalData} />}
        </>
    );
};