import React, { useState, useEffect } from "react";
import {
    RatingContainer,
    ReviewContainer,
    ReviewImage
} from "./CommonComponent";
import Skeleton from 'react-loading-skeleton'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { DynamicStar } from 'react-dynamic-star';
import GoogleLogo from "../assets/googlelogo.png";
import TrustPilotLogo from "../assets/justdiallogo.webp";


export default function Testimonials() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => { setLoading(false) }, 2000);
    }, []);

    const settings = {
        dots: true,
        centerMode: true,
        centerPadding: '60px',
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    centerMode: false,
                    centerPadding: '40px',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    centerMode: false,
                    centerPadding: '40px',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (

        <Slider {...settings}>
            <div>
                <ReviewContainer>
                    {loading ? <Skeleton circle height="80px" width="80px" /> :
                        <ReviewImage src={GoogleLogo} logo alt="google-logo" />
                    }
                    {loading ? <Skeleton width={200} /> : (
                        <h5>Sivagnanam T</h5>
                    )}
                    {loading ? <Skeleton width={100} /> : (
                        <h4>Customer</h4>
                    )}
                    <RatingContainer>
                        {loading ? <Skeleton width={150} /> : (
                            <DynamicStar rating={5} width={20} height={20} outlined />
                        )}
                    </RatingContainer>
                    {loading ? <Skeleton count={2.8} /> : (
                        <p>
                            Very Good place to buy JD Tractor.They are the TN's leading tractor dealer and very good ambiance and care
                        </p>
                    )}
                </ReviewContainer>
            </div>
            <div>
                <ReviewContainer padding="true" >
                    {loading ? <Skeleton circle height="80px" width="80px" /> :
                        <ReviewImage src={TrustPilotLogo} width="13%" style={{ paddingTop: '20px' }} alt="justdial-logo" />
                    }
                    {loading ? <Skeleton width={200} /> : (
                        <h5 padding="true" >Kottappa</h5>
                    )}
                    {loading ? <Skeleton width={100} /> : (
                        <h4>Farmer</h4>
                    )}
                    <RatingContainer>
                        {loading ? <Skeleton width={150} /> : (
                            <DynamicStar rating={5} width={20} height={20} outlined />
                        )}
                    </RatingContainer>
                    {loading ? <Skeleton count={2.3} /> : (
                        <p>
                            RACES, always provide us the proper guidance & best service for the product they serve.
                        </p>
                    )}
                </ReviewContainer>
            </div>
            <div>
                <ReviewContainer>
                    {loading ? <Skeleton circle height="80px" width="80px" /> :
                        <ReviewImage src={GoogleLogo} logo alt="google-logo" />
                    }
                    {loading ? <Skeleton width={200} /> : (
                        <h5>Appanna Swamy Ratnam</h5>
                    )}
                    {loading ? <Skeleton width={100} /> : (
                        <h4>Customer</h4>
                    )}
                    <RatingContainer>
                        {loading ? <Skeleton width={150} /> : (
                            <DynamicStar rating={4.5} width={20} height={20} outlined />
                        )}
                    </RatingContainer>
                    {loading ? <Skeleton count={3.3} /> : (
                        <p>
                            Easy location. Good reception. Neat and knowledgeable persons explaining all parameters. Generally, customer friendly.
                        </p>
                    )}
                </ReviewContainer>
            </div>
            <div>
                <ReviewContainer>
                    {loading ? <Skeleton circle height="80px" width="80px" /> :
                        <ReviewImage src={TrustPilotLogo} alt="justdial-logo" width="13%" style={{ paddingTop: '20px' }} />
                    }
                    {loading ? <Skeleton width={200} /> : (
                        <h5>ManiKandan</h5>
                    )}
                    {loading ? <Skeleton width={100} /> : (
                        <h4>Customer</h4>
                    )}
                    <RatingContainer>
                        {loading ? <Skeleton width={150} /> : (
                            <DynamicStar rating={4.5} width={20} height={20} outlined />
                        )}
                    </RatingContainer>
                    {loading ? <Skeleton count={1.3} /> : (
                        <p>
                            Nice Customer Support and Best in Class Service.
                        </p>
                    )}
                </ReviewContainer>
            </div>
        </Slider>
    );
};