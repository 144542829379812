import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import {
    GoogleMap,
    Marker,
    InfoWindow,
    LoadScript
} from '@react-google-maps/api';
import MapMarker from "../assets/marker.png";
import branch from 'data/BranchData';

const containerStyle = {
    width: '100%',
    height: '100vh'
}

const center = {
    lat: 11.1271,
    lng: 78.6569
}

export default function Map() {
    //eslint-disable-next-line
    const [branchData, setBranchData] = useState(branch);
    const [selectedData, setSelectedData] = useState({});
    const [infoWindowPosition, setInfoWindowPosition] = useState({});

    const getMapMarkerDetails = (branch) => {
        setSelectedData(branch);
        const markerData = branch;
        let infoWindowPosition = {};
        infoWindowPosition.lat = markerData.coOrdinates.latitude;
        infoWindowPosition.lng = markerData.coOrdinates.longitude;
        setInfoWindowPosition(infoWindowPosition);
    };

    return (
        <div>
            <LoadScript
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                id="script-loader"
            >
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    mapTypeId="HYBRID"
                    zoom={7}
                    // onLoad={(map) => { this.setState({ loadedMapData: map }) }
                    position={center}
                    options={{
                        maxZoom: 21,
                        minZoom: 3,
                        fullscreenControl: false,
                    }}
                >
                    {branchData.map((branch) => (
                        <div key={branch.id}>
                            <Marker
                                key={branch.id}
                                position={{
                                    lat: branch.coOrdinates.latitude,
                                    lng: branch.coOrdinates.longitude,
                                }}
                                onClick={() => getMapMarkerDetails(branch)}
                                // onClickableChanged={{ zoom: 20 }}
                                icon={{ url: MapMarker }}
                            />

                        </div>
                    ))}
                    {selectedData && infoWindowPosition && (
                        <>
                            <InfoWindow
                                anchor
                                onCloseClick={() => setSelectedData('')}
                                position={infoWindowPosition}
                            >
                                <div style={{ padding: 12, width: '250px', height: '200px' }}>
                                    <div className=" col p-0 text-center" >
                                        <h6
                                            className="text-uppercase font-weight-bold mb-1"
                                            style={{ fontSize: "18px", wordWrap: "break-word" }}
                                        >
                                            {selectedData.company}
                                        </h6>
                                        <p className="mb-0">{selectedData.branch}</p>
                                        <div className="row p-0">
                                            {/* <Link to="/admin/Address/Country/CountryList"> */}
                                            <h6>{selectedData.address}</h6>
                                            {/* </Link> */}
                                        </div>
                                        <div className='row'>
                                            {/* <Link to="/admin/Address/Country/CountryList"> */}
                                            <p>{selectedData.mail}</p>
                                            {/* </Link> */}
                                        </div>
                                        <div className='row text-right'>
                                            {/* <Link to={this.props} onClick={() => { this.getDirectionOnGoogleMap() }}> */}
                                            <p>{selectedData.phone}</p>
                                            {/* </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </InfoWindow>
                        </>
                    )}
                </GoogleMap>
            </LoadScript>
        </div>
    );
};