import React from "react";
import "twin.macro";
import styled from "styled-components";
import {
    ImageContainer,
    ImageOverlayLayer,
    ImageOverlayText
} from './CommonComponent.js';
import SEO from "./Seo.js";
import ContactIng from '../assets/jd.jpg';
import Video from "../assets/company.mp4";
import Founder from "../assets/images/founder.png";
import Trophy1 from "../assets/trophy/GoldenTrophy.jpg";
import Trophy2 from "../assets/trophy/prize.jpg";
import Trophy3 from "../assets/trophy/9.jpg";
import Trophy4 from "../assets/trophy/20.jpg";
import Trophy5 from "../assets/trophy/21.jpg";
import Trophy6 from "../assets/trophy/22.jpg";

const AwardImageContainer = styled.div`
height: inherit !important;
`
const AwardImage = styled.img`
height: inherit !important;
`
const AwardDetails = styled.div`
display: block;
margin-top: auto;
margin-bottom: auto;
`
const AwardTitle = styled.h5`
font-weight: 700;
`
const AwardPara = styled.p`
font-weight: 400;
`
const Image = styled.div`
border: 0px;
display: flex;
justify-content: center !important;
margin: 0px !important;
border-radius: 50% !important;
`
const Management = styled.div`
diplay: flex !important;
justify-content: center !important;
`;

const Slider = styled.div`
width: 400px;
height: 100% !important;
`
export default function Overview() {

    return (
        <>
            <SEO
                title='Overview - Races'
                description='Races Company Overview'
                name='Races'
                type='article' />
            <div className="containerClass">
                <ImageContainer src={ContactIng} alt="about-us" height="345px" />
                <ImageOverlayLayer data-aos={"fade-down"} data-aos-duration={"1000"}>
                    <ImageOverlayText>Overview</ImageOverlayText>
                </ImageOverlayLayer>
            </div>
            <div className="container" tw="lg:py-16 py-10">
                <div className="row align-items-center flex-column flex-lg-row">
                    <div className="col-md-12 col-lg-12">
                        <div className="py-4 text-center">
                            <p>Placing customer satisfaction first, Integrating sales with service and serviceparts in a single convenient location, we contribute to speedy and efficient service, allowing customers to experience the convience.</p>
                        </div>
                        <div className="py-4 text-center">
                            <video controls autoPlay width="100%">
                                <source src={Video} type="video/mp4" />
                                <source src={Video} type="video/ogg" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-lg-12 px-2">
                    <div className="py-4">
                        <h4 className="color" tw="font-bold my-5">OUR MANAGEMENT</h4>
                    </div>
                    <div className="row">
                        <Management className="col">
                            <Image>
                                <img src={Founder} width="200px" height="200px" alt="founder" />
                            </Image>
                            <h6 tw="font-bold my-5 uppercase text-center">Partner</h6>
                        </Management>
                        <Management className="col">
                            <Image>
                                <img src={Founder} width="200px" height="200px" alt="founder" />
                            </Image>
                            <h6 tw="font-bold my-5 text-center uppercase">Partner</h6>
                        </Management>
                        <Management className="col">
                            <Image>
                                <img src={Founder} width="200px" height="200px" alt="founder" />
                            </Image>
                            <h6 tw="font-bold my-5 uppercase text-center">CEO</h6>
                        </Management>
                    </div>
                </div>
                <div className="col-md-12 col-lg-12 px-2">
                    <div className="py-4">
                        <h4 className="color" tw="font-bold my-5">AWARDS & ACHIEVEMENTS</h4>
                    </div>
                    <div className="sliderClass">
                        <div className="slide-track">
                            <div className="slide">
                                <Slider className="p-3 row">
                                    <AwardImageContainer className="col">
                                        <AwardImage src={Trophy1} />
                                    </AwardImageContainer>
                                    <AwardDetails className="col">
                                        <AwardTitle>All India Harvester Sales Appreciation</AwardTitle>
                                        <AwardPara>By : Standard Corporation</AwardPara>
                                        <AwardPara>In : 2007</AwardPara>
                                    </AwardDetails>
                                </Slider>
                            </div>
                            <div className="slide">
                                <Slider className="p-3 row">
                                    <AwardImageContainer className="col">
                                        <AwardImage src={Trophy6} />
                                    </AwardImageContainer>
                                    <AwardDetails className="col">
                                        <AwardTitle>Premier 500 Club Award</AwardTitle>
                                        <AwardPara>By : John Deere</AwardPara>
                                        <AwardPara>In : 2009</AwardPara>
                                    </AwardDetails>
                                </Slider>
                            </div>
                            <div className="slide">
                                <Slider className="p-3 row">
                                    <AwardImageContainer className="col">
                                        <AwardImage src={Trophy3} />
                                    </AwardImageContainer>
                                    <AwardDetails className="col">
                                        <AwardTitle>Premier 200 Club Award</AwardTitle>
                                        <AwardPara>By : John Deere</AwardPara>
                                        <AwardPara>In : 2010</AwardPara>
                                    </AwardDetails>
                                </Slider>
                            </div>
                            <div className="slide">
                                <Slider className="p-3 row">
                                    <AwardImageContainer className="col">
                                        <AwardImage src={Trophy4} />
                                    </AwardImageContainer>
                                    <AwardDetails className="col">
                                        <AwardTitle>All India Sales Performance 2nd Runner up</AwardTitle>
                                        <AwardPara>By : John Deere</AwardPara>
                                        <AwardPara>In : 2010</AwardPara>
                                    </AwardDetails>
                                </Slider>
                            </div>
                            <div className="slide">
                                <Slider className="p-3 row">
                                    <AwardImageContainer className="col">
                                        <AwardImage src={Trophy2} />
                                    </AwardImageContainer>
                                    <AwardDetails className="col">
                                        <AwardTitle>Business Leaders of the State</AwardTitle>
                                        <AwardPara>By : John Deere</AwardPara>
                                        <AwardPara>In : 2011</AwardPara>
                                    </AwardDetails>
                                </Slider>
                            </div>
                            <div className="slide">
                                <Slider className="p-3 row">
                                    <AwardImageContainer className="col">
                                        <AwardImage src={Trophy5} />
                                    </AwardImageContainer>
                                    <AwardDetails className="col">
                                        <AwardTitle>Prestigious club -500 Award</AwardTitle>
                                        <AwardPara>By : John Deere</AwardPara>
                                        <AwardPara>In : 2010</AwardPara>
                                    </AwardDetails>
                                </Slider>
                            </div>
                            <div className="slide">
                                <Slider className="p-3 row">
                                    <AwardImageContainer className="col">
                                        <AwardImage src={Trophy1} />
                                    </AwardImageContainer>
                                    <AwardDetails className="col">
                                        <AwardTitle>Premier 200 Club Award</AwardTitle>
                                        <AwardPara>By : John Deere</AwardPara>
                                        <AwardPara>In : 2012</AwardPara>
                                    </AwardDetails>
                                </Slider>
                            </div>
                            <div className="slide">
                                <Slider className="p-3 row">
                                    <AwardImageContainer className="col">
                                        <AwardImage src={Trophy2} />
                                    </AwardImageContainer>
                                    <AwardDetails className="col">
                                        <AwardTitle>Best Spareparts business (Tamilnadu & Kerala)</AwardTitle>
                                        <AwardPara>By : John Deere</AwardPara>
                                        <AwardPara>In : 2013</AwardPara>
                                    </AwardDetails>
                                </Slider>
                            </div>
                            <div className="slide">
                                <Slider className="p-3 row">
                                    <AwardImageContainer className="col">
                                        <AwardImage src={Trophy6} />
                                    </AwardImageContainer>
                                    <AwardDetails className="col">
                                        <AwardTitle>Achieving Parts Business Winner</AwardTitle>
                                        <AwardPara>By : John Deere</AwardPara>
                                        <AwardPara>In : 2015</AwardPara>
                                    </AwardDetails>
                                </Slider>
                            </div>
                            <div className="slide">
                                <Slider className="p-3 row">
                                    <AwardImageContainer className="col">
                                        <AwardImage src={Trophy3} />
                                    </AwardImageContainer>
                                    <AwardDetails className="col">
                                        <AwardTitle>Premier 100 Club Award</AwardTitle>
                                        <AwardPara>By : John Deere</AwardPara>
                                        <AwardPara>In : 2015</AwardPara>
                                    </AwardDetails>
                                </Slider>
                            </div>
                            <div className="slide">
                                <Slider className="p-3 row">
                                    <AwardImageContainer className="col">
                                        <AwardImage src={Trophy3} />
                                    </AwardImageContainer>
                                    <AwardDetails className="col">
                                        <AwardTitle>Highest growth in Parts</AwardTitle>
                                        <AwardPara>By : John Deere</AwardPara>
                                        <AwardPara>In : 2015</AwardPara>
                                    </AwardDetails>
                                </Slider>
                            </div>
                            <div className="slide">
                                <Slider className="p-3 row">
                                    <AwardImageContainer className="col">
                                        <AwardImage src={Trophy1} />
                                    </AwardImageContainer>
                                    <AwardDetails className="col">
                                        <AwardTitle>Premier 200 Club Award</AwardTitle>
                                        <AwardPara>By : John Deere</AwardPara>
                                        <AwardPara>In : 2016</AwardPara>
                                    </AwardDetails>
                                </Slider>
                            </div>
                            <div className="slide">
                                <Slider className="p-3 row">
                                    <AwardImageContainer className="col">
                                        <AwardImage src={Trophy6} />
                                    </AwardImageContainer>
                                    <AwardDetails className="col">
                                        <AwardTitle>Outstanding Contribution to HDFC Bank Tractor Loans</AwardTitle>
                                        <AwardPara>By : John Deere</AwardPara>
                                        <AwardPara>In : 2016</AwardPara>
                                    </AwardDetails>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};