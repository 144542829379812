import React, { useState } from "react";
import styled from "styled-components";
import { devices } from "./CommonComponent.js";
import ScrollTop from '../assets/topscroll.svg';

const StickyToTopElement = styled.div`
background-color: #ffffff;
box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 3px 7px;
scroll-behavior:smooth;
color: white !important;
text-align: center;
cursor: pointer;
display: ${props => !!props.visible ? 'block' : 'none'};
border-radius: 30px;
font-weight: bold;
text-decoration: none !important;
width: 50px;  
top: 88vh;
right: 20px !important;
z-index: 9;
-webkit-transform: rotate(-90deg); 
-moz-transform: rotate(-90deg);    
transform:rotate(-90deg);
position: fixed !important; 
position: -webkit-sticky;
@media ${devices.mobileS} { 
    padding-top: 5px;
    padding-bottom: 5px;
    height: 45px;
    right: -65px;
  }
@media ${devices.laptop} { 
    padding-top: 12px;
    padding-bottom: 15px;
    height: 50px;
    right: -50px;
  }
: {
    background-color: #c4c4c4;
}
`
const ScrollImg = styled.img`
-webkit-transform: rotate(90deg); 
-moz-transform: rotate(90deg);    
transform:rotate(90deg);
width: 12px;
@media ${devices.laptop}{
    width: 24px;
}
`


export default function TopperScroll() {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const handleScroll = () => {
        window.scrollTo(0, 0);
    };

    window.addEventListener('scroll', toggleVisible);
    return (
        <StickyToTopElement visible={visible} onClick={handleScroll}>
            <ScrollImg src={ScrollTop} width="100%" height="100%" alt="scroll-to-top"/>
        </StickyToTopElement>
    )
};