import React from "react";
import "twin.macro";
import styled, { keyframes } from "styled-components";
import {
    ImageContainer,
    ImageOverlayLayer,
    ImageOverlayText
} from './CommonComponent.js';
import SEO from "./Seo.js";
import AboutImg from '../assets/Slider-Compacts-v1-2.jpg';

const ProgressBar = styled.div`
height: 35px;
margin-bottom: 15px !important; 
background-color: #eeeeee;
border-radius: 0;
`
const Process = keyframes`
0% {
    width: 10%;
  }
  100% {
    width: 100%;
  }
`
const Processer = styled.div`
padding: 8px 0;
text-indent: 16px;
text-align: left;
font-size: 16px;
font-weight: 700;
background-color: #c7081b;
color: white;
-webkit-animation: ${Process} 2s ease !important;
-moz-animation: ${Process} 2s ease !important;
-o-animation: ${Process} 2s ease !important;
animation: ${Process} 2s ease !important;
`
const ListItems = styled.li`
list-style: none;
padding-top:3px;
padding-bottom:3px;
&:before{
    width: 16px;
    height: 16px;
    background: rgb(199 8 27);
    font-family: FontAwesome;
    content: "\f105";
    margin-right: 10px;
    display: inline-block;
    color: rgb(255 255 255);
    text-align: center;
    vertical-align: middle;
    line-height: 16px;
    font-size: 12px;
}
`
export default function AboutUs() {
    return (
        <>
            <SEO
                title='About Us - Races'
                description='No 1 John Deere Dealers with 100% customer statisfication all over Tamil Nadu'
                name='Races'
                type='article' />
            <div className="containerClass">
                <ImageContainer src={AboutImg} alt="about-us" />
                <ImageOverlayLayer data-aos={"fade-down"} data-aos-duration={"1000"}>
                    <ImageOverlayText>About Us</ImageOverlayText>
                    {/* <p>Attract, engage and convert more qualified vehicle shoppers </p> */}
                </ImageOverlayLayer>
            </div>
            <div className="container" tw="lg:py-16 py-10">
                <div className="row align-items-center flex-column flex-lg-row">
                    <div className="col-md-12 col-lg-8">
                        <div className="py-4">
                            <h4 className="color" tw="font-bold">OUR VISION</h4>
                            <p ><span className="firstcharacter">V</span>To attain the leadership in the Industry and be the First name to the customer to think of when they need the service or equipment for the products We offer and the market We serve</p>
                        </div>
                        <div className="py-4">
                            <h4 className="color" tw="font-bold my-5">OUR MISSION</h4>
                            <p><span className="firstcharacter">M</span>Be the Benchmarking dealer in all aspects in the eyes of the customers, employees, financiers and the principals. Dealing with honesty, integrity, openness and fairness to all.</p>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4 px-2">
                        <h4 className="color" tw="font-bold">WHAT WE SPECIALIZE IN</h4>
                        <div className="py-2">
                            <ProgressBar>
                                <Processer>Sales</Processer>
                            </ProgressBar>
                            <ProgressBar>
                                <Processer>Service</Processer>
                            </ProgressBar>
                            <ProgressBar>
                                <Processer>Parts</Processer>
                            </ProgressBar>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="py-4">
                        <h4 className="color" tw="font-bold">WHY CHOOSE US?</h4>
                        <ListItems>We aim to provide 100% Customer Satisfaction</ListItems>
                        <ListItems>Adequate of Parts & Oil availability</ListItems>
                        <ListItems>Delivery of parts and service at door step</ListItems>
                        <ListItems>We provide the best coverage in the industry</ListItems>
                    </div>
                </div>
            </div>
        </>
    );
};