import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
    CategoryLink,
    EventContainer,
    Title,
    EventSubContainer,
    Events,
    EventOverlay,
    EventImage,
    EventDetails
} from "./CommonComponent.js";
import SEO from "./Seo.js";
import Skeleton from 'react-loading-skeleton';
import Testimonials from "./Testimonial.js";
import Products from "../assets/images/Tractor.jpeg";
import Services from "../assets/images/Service.jpeg";
import Implements from '../assets/images/implement.jpeg';
import Celebration from "../assets/gallery/g-img9.webp";
import Training from "../assets/training/training4.jpeg";
import Ceremony from "../assets/gallery/hosurbranchOpening/img1.jpeg";
import Workshop from "../assets/gallery/g-img29.jpg";
import { Link } from "react-router-dom";

const sizes = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
};

const devices = {
    mobileS: `(min-width: ${sizes.mobileS})`,
    mobileM: `(min-width: ${sizes.mobileM})`,
    mobileL: `(min-width: ${sizes.mobileL})`,
    tablet: `(min-width: ${sizes.tablet})`,
    laptop: `(min-width: ${sizes.laptop})`,
    laptopL: `(min-width: ${sizes.laptopL})`,
    desktop: `(min-width: ${sizes.desktop})`,
};

const ProductListContainer = styled.div`
display: flex;
flex-direction: column;
background-color: rgb(229 229 229);
flex: 1 1 auto;
justify-content: center;
padding-top: 1.5rem;
padding-bottom: 1.5rem;
font-size: 1rem;
line-height: 1.5rem; 
@media ${devices.tablet}{
    flex-direction: row; 
    padding-top: 4rem;
    padding-bottom: 4rem;
}
`
const CategoryContainer = styled.div`
max-width: 32rem;
height: auto;
margin-top: 0.75rem;
margin-bottom: 0.75rem;
background-color: #ffffff !important;
margin-left: 1rem;
margin-right: 1rem;
transition: box-shadow .35s ease-in-out;
@media ${devices.tablet}{
    width: 26.5%;
    margin-left: 1.25rem;
    margin-right: 1.25rem; 
}
&:hover{
    box-shadow: 0 0 16px 0 rgb(0 0 0 / 30%);
}
`
const ProductTitle = styled.h2`
color: #c7081b !important;
font-weight: bold;
`

const CardImageContainer = styled.img`
width: 100%; 
height: 100%
`;

const TestimonialContainer = styled.div`
margin-right: 1.5rem;
margin-left: 1.5rem;
@media ${devices.tablet}{
    margin-right: 4.5rem;
    margin-left: 4.5rem;
}
`

export default function Home() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const onPageLoad = () => {
            setLoading(false);
        };
        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad, false);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    return (
        <>
            <SEO
                title='Races | Our people make the difference'
                description='Beginner friendly page for learning React Helmet.'
                name='Company name.'
                type='article' />
            <ProductListContainer>
                <CategoryContainer data-aos={"fade-up"} data-aos-anchor-placement={"top-bottom"} data-aos-duration={"3000"}>
                    <div className="flip-container">
                        <div className="flipper">
                            <div className="front">
                                {loading ? (
                                    <Skeleton
                                        height="100%"
                                        containerClassName="productContainer"
                                    />
                                ) : (
                                    <CardImageContainer src={Implements} className='image-fluid' alt="implement-category" loading="lazy" />
                                )}
                            </div>
                            {!loading &&
                                <div className="back">
                                    <div className="hover_title">IMPLEMENTS</div>
                                    <CategoryLink to="/implement">View More &gt;</CategoryLink>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="p-3">
                        {loading ? <Skeleton width={70} /> : (
                            <Title>IMPLEMENTS</Title>
                        )}
                        {loading ? <Skeleton count={2.5} /> :
                            <p>Choose from a range of various JohnDeere attachments designed for several applications.</p>
                        }
                    </div>
                </CategoryContainer>
                <CategoryContainer data-aos={"fade-up"} data-aos-anchor-placement={"top-bottom"} data-aos-duration={"1000"}>
                    <div className="flip-container">
                        <div className="flipper">
                            <div className="front">
                                {loading ? (
                                    <Skeleton
                                        height="100%"
                                        containerClassName="productContainer"
                                    />
                                ) : (
                                    <CardImageContainer src={Products} className='image-fluid' alt="product-category" />
                                )}
                            </div>
                            {!loading &&
                                <div className="back">
                                    <div className="hover_title">PRODUCTS</div>
                                    <CategoryLink to="/tractors">View More &gt;</CategoryLink>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="p-3">
                        {loading ? <Skeleton width={70} /> : (
                            <Title>PRODUCTS</Title>
                        )}
                        {loading ? <Skeleton count={2.5} /> :
                            <p>JohnDeere manufactures over 300 products. Find the machine best suited for you.</p>
                        }
                    </div>
                </CategoryContainer>
                <CategoryContainer data-aos={"fade-up"} data-aos-anchor-placement={"top-bottom"} data-aos-duration={"1500"}>
                    <div className="flip-container">
                        <div className="flipper">
                            <div className="front">
                                {loading ? (
                                    <Skeleton
                                        height="100%"
                                        containerClassName="productContainer"
                                    />
                                ) : (
                                    <CardImageContainer src={Services} className='image-fluid' alt="service-category" />
                                )}
                            </div>
                            {!loading &&
                                <div className="back">
                                    <div className="hover_title">SERVICES</div>
                                    <CategoryLink to="/service">View More &gt;</CategoryLink>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="p-3">
                        {loading ? <Skeleton width={70} /> : (
                            <Title>SERVICES</Title>
                        )}
                        {loading ? <Skeleton count={3.5} /> :
                            <p>John Deere resolutely stands behind its products. We provide a wealth of resources to keep your equipment running at peak efficiency for years to come.</p>
                        }
                    </div>
                </CategoryContainer>
            </ProductListContainer>
            <div className="text-center py-5 font-bold">
                <ProductTitle>TESTIMONIAL</ProductTitle>
                <p tw="text-3xl">Join 1500+ Happy Customer to Experience the Great Experience..</p>
                <TestimonialContainer>
                    <Testimonials />
                </TestimonialContainer>
            </div>
            <div className="text-center pb-5 font-bold">
                <ProductTitle className="pt-3">GALLERY & EVENTS</ProductTitle>
                <p tw="text-3xl">We Always tend to Celebrate our Happy Moments With Our Customer.</p>
                <EventContainer>
                    <EventSubContainer>
                        {loading ? <Skeleton width="290px" height="220px" style={{ marginRight: '10px', marginLeft: '10px' }} /> : (
                            <Events>
                                <Link to="/gallery">
                                    <EventOverlay />
                                    <EventImage src={Celebration} alt="celebration" />
                                    <EventDetails classname="fadeIn-bottom">
                                        <h3>CELEBRATION</h3>
                                        <p>VIEW MORE <i className="fa fa-arrow-right" aria-hidden={true} /></p>
                                    </EventDetails>
                                </Link>
                            </Events>
                        )}
                    </EventSubContainer>
                    <EventSubContainer>
                        {loading ? <Skeleton width="250px" height="220px" style={{ marginRight: '10px', marginLeft: '10px' }} /> : (
                            <Events>
                                <Link to="/gallery">
                                    <EventOverlay />
                                    <EventImage src={Training} alt="training" />
                                    <EventDetails className="fadeIn-bottom">
                                        <h3>TRAINING</h3>
                                        <p>VIEW MORE <i className="fa fa-arrow-right" aria-hidden={true} /></p>
                                    </EventDetails>
                                </Link>
                            </Events>
                        )}
                    </EventSubContainer>
                    <EventSubContainer>
                        {loading ? <Skeleton width="250px" height="220px" style={{ marginRight: '10px', marginLeft: '10px' }} /> : (
                            <Events>
                                <Link to="/gallery">
                                    <EventOverlay />
                                    <EventImage src={Workshop} alt="workshop" />
                                    <EventDetails className="fadeIn-bottom">
                                        <h3>WORKSHOP</h3>
                                        <p>VIEW MORE <i className="fa fa-arrow-right" aria-hidden={true} /></p>
                                    </EventDetails>
                                </Link>
                            </Events>
                        )}
                    </EventSubContainer>
                    <EventSubContainer>
                        {loading ? <Skeleton width="290px" height="220px" style={{ marginRight: '10px', marginLeft: '10px' }} /> : (
                            <Events>
                                <Link to="/gallery">
                                    <EventOverlay />
                                    <EventImage src={Ceremony} alt="ceremony" />
                                    <EventDetails className="fadeIn-bottom">
                                        <h3>CEREMONY</h3>
                                        <p>VIEW MORE <i className="fa fa-arrow-right" aria-hidden={true} /></p>
                                    </EventDetails>
                                </Link>
                            </Events>
                        )}
                    </EventSubContainer>
                </EventContainer>
            </div>
        </>
    );
};
