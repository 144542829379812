import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import Slider1 from '../assets/slider03.jpg';
// import Slider2 from '../assets/slider01.jpeg';
// import Slider3 from '../assets/slider02.jpeg';
import Slider01 from "../assets/slider-01.jpeg";
import Slider02 from "../assets/slider-02.jpeg";
import Slider03 from "../assets/slider-03.jpeg";
import Slider04 from "../assets/slider-04.jpg";
import "../styles/globalStyles.css";
import Skeleton from "react-loading-skeleton";

const Container = styled.div`
position:relative`;

export default function ImageSlider() {
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [imageList, setImageList] = useState([
    Slider02,
    Slider03,
    Slider01,
    Slider04,
  ]);

  useEffect(() => {
    setTimeout(() => { setLoading(false) }, 2000);
  }, []);

  return (
    <Container>
      <Carousel
        animationHandler="fade"
        showThumbs={false}
        autoPlay
        infiniteLoop={true}
        interval={5000}
        transitionTime={1000}
        tw="text-center"
      >
        {loading ? <Skeleton height="750px" width="100%" /> : (
          imageList.map((img, idx) => (
            <div key={idx}>
              <img alt="slider-images" src={img} />
            </div>
          ))

        )}
      </Carousel>

    </Container>
  );
};
